.work-type-image{
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.intro{
    /*width: 75%;*/
    /*margin-left: auto;*/
    /*margin-right: auto;*/
    font-size: 18px;
}

.signin-button{
    text-decoration: none;
    color: #774b86;
    font-weight: bold;
    font-size: 16px;
}

.forgot-password-new-here-button{
    text-decoration: none;
    font-weight: bold;
    color: #774b86;
}

.forgot-password-new-here-button:hover{
    text-decoration: underline;
    color: #774b86;
}

.signin-button:hover{
    text-decoration: underline;
    color: #774b86;
}

.help-icon{
    position: absolute;
    top: -8px;
    right: 4px;
}

/*Extra small devices (portrait phones, less than 576px)*/
@media (max-width: 575.98px) {

}

/*Small devices (landscape phones, 576px and up)*/
@media (min-width: 576px) and (max-width: 767.98px) {

}

/*Medium devices (tablets, 768px and up)*/
@media (min-width: 768px) and (max-width: 991.98px) {

}

/*Large devices (desktops, 992px and up)*/
@media (min-width: 992px) and (max-width: 1199.98px) {

}

/*Extra large devices (large desktops, 1200px and up)*/
@media (min-width: 1200px) {

}

/*Custom breakpoints*/
@media (min-width: 576px) {
    .intro-grid-column{
        padding-left: 0% !important;
        padding-right: 0% !important;
    }
}

/*@media (min-width: 320px) and (max-width: 784px) {
.talentpromo{
        position: fixed;
        top: 75px;
    }
}*/
