.paid-on{
    float: right;
    margin-top: -34px;
    font-size: 14px;
    color: gray;
}

.job-title{

	margin-left:10px;

}