body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.top-container{
    margin-top: 5em;
}
.main-container{
    margin-top:70px;
    width: 100% !important;
}

.full-width-container{
    width: 99% !important;
}

.field-error{
    margin-top: -24px;
    display: block;
    color:red;
    min-height: 25px;
    text-align: left;
}

.radio-field-error{
    position: absolute;
    margin-top: -35px;
    color:red;
}

/* bad way to add spacing below a field
.ui.form .field{
    margin: 0 0 22px !important;
} */

.ui.inverted.menu {
    border: 0 solid transparent;
    background: #774b86;
}

.custom-anchor-tag{
    box-shadow: none !important;
    border: none !important;
    text-decoration: none !important;
    color: #774b86 !important;
    font-weight: bold !important;
    font-size: 16px !important;
    padding: 0 !important;
}

.custom-anchor-tag:hover{
    text-decoration: underline !important;
}

hr{
    border: 0;
    border-top: 1px solid #dededf;
    padding: 0;
}

.text-max-lenght-message{
    position: absolute;
    top:5px;
    color:#cac4c4;
    right: 12px;
    font-size: 12px;
    font-weight: bold;
}

.required-marker{
    color: red;
    margin-right: 4px !important;
}

/*Semantic UI Modal position error fixes*/
.ui.page.modals.transition.visible {
    display: flex !important;
}
.jumbotron.sub-page {
    background: #f6eef9 !important;
}

 /*Mobile */
@media only screen and (max-width: 784px) {
    .main-container{
        margin-top:80px;
    }
}

 /*Tablet / iPad Portrait */
@media only screen and (min-width: 785px) and (max-width: 991px) {
    .main-container{
        margin-top:80px;
    }
}

 /*Computer / Desktop / iPad Landscape */
@media only screen and (min-width: 992px) and (max-width: 1199px) {

}

 /*Large Monitor */
@media only screen and (min-width: 1200px) and (max-width: 1919px) {
    .ui.container {
        width: 1127px;
    }
}

 /*Widescreen Monitor */
@media only screen and (min-width: 1920px) {

}

.Cal__Header__root{
    min-height: 60px !important;
    padding: 10px !important;
}

.Cal__Weekdays__day{
    padding: 5px 0px !important;;
}

.Cal__Header__dateWrapper.Cal__Header__day{
    font-size: 14px !important;
}

.Cal__Day__root{
    font-size: 12px !important;
}
.tp-text{
    color:#949294 !important;
    /* text-shadow: 2px 2px 23px #e9e5ea, 1px 1px 6px #bea4d5; */
}

.footer-container{
    background-color:#f8f9fa;
    padding-top: 50px;
    padding-bottom: 50px;
    /* margin-top: 30px !important; */
    box-shadow: 0 50vh 0 50vh #d4d4d5;
}

.react-tel-input{
    margin-bottom: 20px !important;
    height: 30px !important;
}

.react-tel-input input{
    padding-left: 45px !important;
    height: 30px !important;
}

.ui.blue.button, .ui.blue.buttons .button {
    background-color: #774b86 !important;
    color: #fff;
    text-shadow: none;
    background-image: none;
}

.ui.blue.button:hover, .ui.blue.buttons .button:hover {
    background-color: #9D7EB0 !important;
    color: #fff;
    text-shadow: none;
}

a:hover, a:focus, a:active {
    color: #21ba45 ;
    text-decoration: none;
}
.ui.segments {
    border: 1px solid #e3dee8 !important;
}
.ui.secondary.segment {
    background: #f6f5f7 !important;
    color: rgba(0,0,0,.6);
}

.ui.attached.info.message, .ui.info.message {
    box-shadow: 0 0 0 1px #774a85 inset, 0 0 0 0 transparent !important;
}

.ui.info.message {
    background-color: #f6f5f7 !important;
    color: #774a85;
}

.Cal__Header__root{
    background-color: rgb(119, 74, 133)!important;
    color: rgb(255, 255, 255);
}
.Cal__Weekdays__root{
    background-color: #8a549c !important;
    color: rgb(255, 255, 255);
}

.Cal__Day__root.Cal__Day__selected .Cal__Day__selection {
/*    background-color: rgb(138, 84, 156) !important;*/
}

.Cal__Today__root.Cal__Today__show {
    background-color: #bababc !important;
}

i.blue.icon {
    color: #8a549c !important;
}

.Cal__Today__chevron {
    color: #8a549c !important;
}
.ui.circular.label {
    margin-left: 4px !important;
}

.work-type-image{
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.intro{
    /*width: 75%;*/
    /*margin-left: auto;*/
    /*margin-right: auto;*/
    font-size: 18px;
}

.signin-button{
    text-decoration: none;
    color: #774b86;
    font-weight: bold;
    font-size: 16px;
}

.forgot-password-new-here-button{
    text-decoration: none;
    font-weight: bold;
    color: #774b86;
}

.forgot-password-new-here-button:hover{
    text-decoration: underline;
    color: #774b86;
}

.signin-button:hover{
    text-decoration: underline;
    color: #774b86;
}

.help-icon{
    position: absolute;
    top: -8px;
    right: 4px;
}

/*Extra small devices (portrait phones, less than 576px)*/
@media (max-width: 575.98px) {

}

/*Small devices (landscape phones, 576px and up)*/
@media (min-width: 576px) and (max-width: 767.98px) {

}

/*Medium devices (tablets, 768px and up)*/
@media (min-width: 768px) and (max-width: 991.98px) {

}

/*Large devices (desktops, 992px and up)*/
@media (min-width: 992px) and (max-width: 1199.98px) {

}

/*Extra large devices (large desktops, 1200px and up)*/
@media (min-width: 1200px) {

}

/*Custom breakpoints*/
@media (min-width: 576px) {
    .intro-grid-column{
        padding-left: 0% !important;
        padding-right: 0% !important;
    }
}

/*@media (min-width: 320px) and (max-width: 784px) {
.talentpromo{
        position: fixed;
        top: 75px;
    }
}*/

/*
 * See: https://www.google.com/fonts/license/productsans
 */
/* cyrillic */
@font-face {
  font-family: 'Product Sans';
  font-style: normal;
  font-weight: 300;
  src: local('Product Sans Light'), local('ProductSans-Light'), url(https://fonts.gstatic.com/s/productsans/v9/pxicypQkot1TnFhsFMOfGShdvPWbS2lBkm8.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek */
@font-face {
  font-family: 'Product Sans';
  font-style: normal;
  font-weight: 300;
  src: local('Product Sans Light'), local('ProductSans-Light'), url(https://fonts.gstatic.com/s/productsans/v9/pxicypQkot1TnFhsFMOfGShdvPWbTGlBkm8.woff2) format('woff2');
  unicode-range: U+0370-03FF;
}
/* latin-ext */
@font-face {
  font-family: 'Product Sans';
  font-style: normal;
  font-weight: 300;
  src: local('Product Sans Light'), local('ProductSans-Light'), url(https://fonts.gstatic.com/s/productsans/v9/pxicypQkot1TnFhsFMOfGShdvPWbQWlBkm8.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Product Sans';
  font-style: normal;
  font-weight: 300;
  src: local('Product Sans Light'), local('ProductSans-Light'), url(https://fonts.gstatic.com/s/productsans/v9/pxicypQkot1TnFhsFMOfGShdvPWbT2lB.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic */
@font-face {
  font-family: 'Product Sans';
  font-style: normal;
  font-weight: 400;
  src: local('Product Sans'), local('ProductSans-Regular'), url(https://fonts.gstatic.com/s/productsans/v9/pxiDypQkot1TnFhsFMOfGShVE9eOcEg.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek */
@font-face {
  font-family: 'Product Sans';
  font-style: normal;
  font-weight: 400;
  src: local('Product Sans'), local('ProductSans-Regular'), url(https://fonts.gstatic.com/s/productsans/v9/pxiDypQkot1TnFhsFMOfGShVFNeOcEg.woff2) format('woff2');
  unicode-range: U+0370-03FF;
}
/* latin-ext */
@font-face {
  font-family: 'Product Sans';
  font-style: normal;
  font-weight: 400;
  src: local('Product Sans'), local('ProductSans-Regular'), url(https://fonts.gstatic.com/s/productsans/v9/pxiDypQkot1TnFhsFMOfGShVGdeOcEg.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Product Sans';
  font-style: normal;
  font-weight: 400;
  src: local('Product Sans'), local('ProductSans-Regular'), url(https://fonts.gstatic.com/s/productsans/v9/pxiDypQkot1TnFhsFMOfGShVF9eO.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic */
@font-face {
  font-family: 'Product Sans';
  font-style: normal;
  font-weight: 700;
  src: local('Product Sans Bold'), local('ProductSans-Bold'), url(https://fonts.gstatic.com/s/productsans/v9/pxicypQkot1TnFhsFMOfGShdrPKbS2lBkm8.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek */
@font-face {
  font-family: 'Product Sans';
  font-style: normal;
  font-weight: 700;
  src: local('Product Sans Bold'), local('ProductSans-Bold'), url(https://fonts.gstatic.com/s/productsans/v9/pxicypQkot1TnFhsFMOfGShdrPKbTGlBkm8.woff2) format('woff2');
  unicode-range: U+0370-03FF;
}
/* latin-ext */
@font-face {
  font-family: 'Product Sans';
  font-style: normal;
  font-weight: 700;
  src: local('Product Sans Bold'), local('ProductSans-Bold'), url(https://fonts.gstatic.com/s/productsans/v9/pxicypQkot1TnFhsFMOfGShdrPKbQWlBkm8.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Product Sans';
  font-style: normal;
  font-weight: 700;
  src: local('Product Sans Bold'), local('ProductSans-Bold'), url(https://fonts.gstatic.com/s/productsans/v9/pxicypQkot1TnFhsFMOfGShdrPKbT2lB.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@charset "UTF-8";
body {
  position: relative;
  color: #1b1c1d;
  overflow-x: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Product Sans", Helvetica, Arial, serif;
  line-height: 1.5; }

a {
  color: #774b86;
  text-decoration: none;
  transition: all 0.3s ease;
}

a:focus, a:active {
  color: #774b86;
  text-decoration: none;
}

a:hover {
  color: #9D7EB0;
  text-decoration: underline;
}

.link-muted {
  color: #464a4c; }

.link-reverse {
  color: #fff; }
  .link-reverse:hover {
    color: #fff;
    opacity: 0.7; }

.my-lg {
  margin-top: 5em !important;
  margin-bottom: 5em !important; }

.py-lg {
  padding-top: 5em !important;
  padding-bottom: 5em !important; }

.mt-lg {
  margin-top: 5em !important; }

.mb-lg {
  margin-bottom: 5em !important; }

.pt-lg {
  padding-top: 5em !important; }

.pb-lg {
  padding-bottom: 5em !important; }

.mb-4 {
  margin-bottom: 2rem !important; }

@media (min-width: 768px) {
  .my-md-lg {
    margin-top: 5em !important;
    margin-bottom: 5em !important; } }

.display-5 {
  font-size: 2rem;
  font-weight: 300;
  line-height: 1.5; }

.bg-light {
  background-color: #f8faff !important; }

.bg-white {
  background-color: #fff !important; }

.bg-primary {
  background-color: #774b86 !important; }

.bg-gradient {
  background-image: linear-gradient(-152deg, #6937EA 0%, #377FEA 65%); }

.light-text {
  color: #b5bcc0; }

.text-inverse {
  color: #fff; }

.form-control::-webkit-input-placeholder {
  color: #636c72;
  opacity: 0.5; }

.form-control::-ms-input-placeholder {
  color: #636c72;
  opacity: 0.5; }

.form-control::placeholder {
  color: #636c72;
  opacity: 0.5; }

.form-control::-webkit-input-placeholder {
  color: #636c72;
  opacity: 0.5; }

.text-muted {
  color: #a7a2ce !important; }

/*FONT*/
.sans-serif-font {
  font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif !important; }

.thin-font {
  font-weight: 300 !important; }

/*font awesome*/
.fa-valign {
  vertical-align: middle; }

/*-----------------------------------
 * Section
 *-----------------------------------*/
.section {
  padding-top: 3em;
  padding-bottom: 3em; }

.section-title {
  margin: 2em auto; }

/*-----------------------------------
 * OWL CAROUSEL
 *-----------------------------------*/
/**
 * Owl Carousel v2.2.1
 * Copyright 2013-2017 David Deutsch
 * Licensed under  ()
 */
/*
 * 	Default theme - Owl Carousel CSS File
 */
.owl-theme .owl-nav {
  margin-top: 10px;
  text-align: center;
  -webkit-tap-highlight-color: transparent; }

.owl-theme .owl-nav [class*='owl-'] {
  color: #FFF;
  font-size: 14px;
  margin: 5px;
  padding: 4px 7px;
  background: #D6D6D6;
  display: inline-block;
  cursor: pointer;
  border-radius: 3px; }

.owl-theme .owl-nav [class*='owl-']:hover {
  background: #869791;
  color: #FFF;
  text-decoration: none; }

.owl-theme .owl-nav .disabled {
  opacity: 0.5;
  cursor: default; }

.owl-theme .owl-nav.disabled + .owl-dots {
  margin-top: 10px; }

.owl-theme .owl-dots {
  text-align: center;
  -webkit-tap-highlight-color: transparent; }

.owl-theme .owl-dots .owl-dot {
  display: inline-block;
  zoom: 1;
  *display: inline; }

.owl-theme .owl-dots .owl-dot span {
  width: 10px;
  height: 10px;
  margin: 5px 7px;
  background: #D6D6D6;
  display: block;
  -webkit-backface-visibility: visible;
  transition: opacity 200ms ease;
  border-radius: 30px; }

.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
  background: #869791; }

.owl-carousel .owl-item .citation img {
  width: 75px;
  display: inline; }

.btn {
  text-transform: uppercase;
  font-size: 14px;
  border-radius: 30px;
  transition: all 0.3s ease; }
  .navbar-nav .btn {
    margin-left: 10px;
    margin-right: 10px;
    padding-left: 15px;
    padding-right: 15px; }

.btn-lg {
  font-size: 16px;
  letter-spacing: 2px;
  padding: 17px 35px; }
  .btn-lg:hover, .btn-lg:active {
    -webkit-transform: translateY(-2px);
    transform: translateY(-2px); }

.btn-xl {
  padding: 12px 35px; }

.btn-sm {
  font-size: 14px;
  letter-spacing: 0; }

.btn-primary,
.btn-primary:focus {
  background-color: #774b86;
  background-image: linear-gradient(90deg, #774b86, #bea6d4);
  border: 0;
  color: #fff; }
  .btn-primary:hover, .btn-primary:active,
  .btn-primary:focus:hover,
  .btn-primary:focus:active {
    color: #fff;
    background-color: #6429ff;
    background-image: linear-gradient(90deg, #bea6d4, #774b86); }

.btn-outline,
.btn-outline:focus {
  color: #fff;
  background-image: none;
  background-color: transparent;
  border-color: #fff; }
  .btn-outline:hover, .btn-outline:active,
  .btn-outline:focus:hover,
  .btn-outline:focus:active {
    color: #292b2c;
    background-color: #fff;
    border-color: #fff; }

.btn-outline-primary {
  color: #774b86;
  background-image: none;
  background-color: transparent;
  border-color: #774b86; }
  .btn-outline-primary:hover, .btn-outline-primary:active {
    color: #fff;
    background-color: #774b86;
    border-color: #774b86;
    background-image: linear-gradient(90deg, #774b86, #bea6d4); }

/* ------------------------------------------------

Page    : Start.ly SaaS CSS
Version : 1.0
Author  : Surjith S M
URI     : http://themeforest.net/user/surjithctly

TABLE OF CONTENTS
-----------------

1. Header
2. navbar
3. One Page
4. Cards
5. Portfolio
6. Video
7. Team
8. Contact
9. Footer

Copyright © All rights Reserved
Surjith S M / @surjithctly

-------------------------------------------------- */
/*-----------------------------------
 * Header
 *-----------------------------------*/
header {
  position: relative; }

.gradient-mask {
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  background-image: -webkit-gradient(linear, left top, right top, from(#774b86), to(#bea6d4)); }

/*Swiper Slider*/
.swiper-container {
  width: 100%;
  height: 850px;
  height: 100vh; }
  .swiper-container .jumbotron {
    min-height: auto; }

@media (max-width: 767px) {
  .swiper-button-prev,
  .swiper-button-next {
    display: none; } }

.parallax-bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 130%;
  height: 100%;
  background-size: cover;
  background-position: center; }
  .parallax-bg.bg-overlay:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.4); }

.bg-video video {
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  background: url(/static/media/poster.c8bc50b7.jpg) no-repeat;
  background-size: cover;
  transition: 1s opacity; }

.bg-video.align-bottom video {
  top: auto;
  bottom: 0;
  -webkit-transform: translateX(-50%) translateY(0);
  transform: translateX(-50%) translateY(0); }

.swiper-button-prev,
.swiper-container-rtl .swiper-button-next {
  left: 30px; }

.swiper-button-next,
.swiper-container-rtl .swiper-button-prev {
  right: 30px; }

/*end swiper css*/
.jumbotron {
  width: 100%;
  background-color: transparent;
  margin-bottom: 0; }
  @media (min-width: 576px) {
    .jumbotron {
      padding: 2rem 0; }
      .jumbotron.sub-page {
        padding: 0 0 2rem 0; } }
  .jumbotron.sub-page {
    min-height: 290px;
    background: #f8faff; }
  .jumbotron .lead {
    max-width: 600px;
    margin: auto; }
  .jumbotron .btn-lg {
    padding: 17px 35px; }
  .jumbotron .btn {
    margin: 10px; }

.jumbotron-two {
  padding: 2rem 0; }
  .jumbotron-two .lead {
    max-width: 500px;
    margin: 0; }

.overflow-hidden {
  overflow-x: hidden; }

.bg-shape {
  position: absolute;
  background-color: #774b86;
  background-image: linear-gradient(100deg, #774b86, #bea6d4);
  top: -350px;
  right: -110px;
  border-radius: 8%;
  width: 50%;
  height: 800px;
  -webkit-transform: skew(3deg, 30deg);
  transform: skew(3deg, 30deg);
  opacity: 1;
  z-index: 0; }
  @media (max-width: 991px) {
    .bg-shape {
      top: -150px;
      right: 0;
      width: 20%;
      height: 500px; } }
  @media (max-width: 767px) {
    .bg-shape {
      display: none; } }

.bg-circle {
  position: absolute;
  background-color: #774b86;
  background-image: linear-gradient(100deg, #774b86, #bea6d4);
  top: -400px;
  left: -350px;
  border-radius: 100%;
  height: 800px;
  width: 800px;
  opacity: 0.2;
  z-index: -1; }
  @media (max-width: 991px) {
    .bg-circle {
      height: 400px;
      width: 400px;
      top: -200px;
      left: -200px; } }
  @media (max-width: 767px) {
    .bg-circle {
      display: none; } }

.bg-circle-two {
  position: absolute;
  background-color: #774b86;
  background-image: linear-gradient(100deg, #774b86, #bea6d4);
  top: 150px;
  left: 350px;
  border-radius: 100%;
  height: 100px;
  width: 100px;
  opacity: 0.8;
  z-index: -1; }
  @media (max-width: 991px) {
    .bg-circle-two {
      top: 150px;
      left: 70px;
      height: 50px;
      width: 50px; } }
  @media (max-width: 767px) {
    .bg-circle-two {
      display: none; } }

.hero-copy .container {
  max-width: 40rem; }

[class^="display"] {
  font-family: "Product Sans", Helvetica, Arial, serif;
  font-weight: 700; }

@media (max-width: 991px) {
  .display-3 {
    font-size: 4rem; }
  .display-5 {
    font-size: 1.4rem; } }

@media (max-width: 767px) {
  .display-3 {
    font-size: 2.2rem; }
  .display-4 {
    font-size: 2.5rem; } }

.split-bg {
  background: linear-gradient(to bottom, #f8faff 0%, #f8faff 50%, white 50%); }

.macbook-hero {
  max-width: 1100px;
  position: relative;
  margin: 0 auto; }
  .macbook-hero .macbook-screen {
    position: absolute;
    left: 12.4%;
    top: 5.4%;
    right: 11.8%;
    bottom: 30.6%;
    max-width: 835px;
    max-height: 522px;
    background: #fff; }
    .macbook-hero .macbook-screen img {
      width: 100%;
      height: 100%; }

.section {
  position: relative; }
  .section.bg-overlay:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.4); }
  .section.bg-gradient:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: linear-gradient(to right, rgba(106, 17, 203, 0.76) 0%, rgba(37, 117, 252, 0.77) 100%); }

/*-----------------------------------
 * NAVBAR
 *-----------------------------------*/
.navbar {
  transition: all 0.3s ease;
  padding: 1rem; }
  .navbar .btn {
    box-shadow: none; }
  .navbar.bg-light {
    padding: 0.4rem;
    background: #FFF !important;
    box-shadow: 0px 9px 68px 0px rgba(62, 57, 107, 0.1); }

.navbar-toggler {
  margin-top: 5px; }

.navbar-border {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2); }

.navbar-transparent {
  background: transparent;
  transition: all 0.3s ease; }

.nav-link {
  padding: .3em 1em; }
  .nav-link .pe-7s-angle-down {
    margin: -13px -13px -13px -8px;
    position: relative;
    top: 8px; }

@media (max-width: 767px) {
  .navbar-toggleable-md > .container {
    width: 100%; }
  .nav-link {
    padding: .3em 0.6em; } }

@media (max-width: 991px) {
  body:not(.sticky) .navbar:not(.navbar-light).menu-is-open {
    background: rgba(0, 0, 0, 0.7); }
  .navbar-nav {
    margin-bottom: 1em; }
  .navbar-toggleable-md .navbar-nav .dropdown-menu {
    text-align: center; } }

@media (min-width: 992px) {
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 1rem;
    padding-left: 1rem; } }

.navbar-nav .nav-link {
  font-size: 16px;
  text-align: center;
  position: relative; }
  .navbar-inverse .navbar-nav .nav-link {
    color: rgba(255, 255, 255, 0.8);
    text-transform: uppercase;
    font-size: 14px; }
  @media (min-width: 992px) {
    .navbar-nav .nav-link:before {
      content: "";
      height: 1px;
      position: absolute;
      bottom: 5px;
      left: 50%;
      right: 50%;
      border-bottom: 1px solid #774b86;
      margin-right: auto;
      margin-left: auto;
      transition: all 0.5s ease; } }

.navbar-inverse .navbar-nav .nav-link.active {
  color: #774b86; }
  @media (min-width: 992px) {
    .navbar-inverse .navbar-nav .nav-link.active:after {
      content: "";
      height: 1px;
      position: absolute;
      bottom: 5px;
      left: 0;
      right: 0;
      border-bottom: 1px solid #774b86;
      margin-right: 0.95rem;
      margin-left: 0.95rem; } }

@media (min-width: 992px) {
  .navbar-toggleable-md .navbar-nav .nav-link {
    padding-right: 1rem;
    padding-left: 1rem; } }

/*Dropdown*/
.dropdown-menu {
  border-radius: 0;
  border: 1px solid rgba(0, 0, 0, 0.05);
  box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.05); }

.dropdown-item {
  padding: 5px 2rem 5px 1.2rem; }

.dropdown-item.active,
.dropdown-item:active {
  color: #000;
  background-color: #f8faff; }

/*-----------------------------------
 * YOUTUBE VIDEO
 *-----------------------------------*/
iframe {
  display: block; }

.youtube {
  margin-top: 10px;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  overflow: hidden;
  transition: all 200ms ease-out;
  cursor: pointer; }

.youtube .play {
  background: #FFF;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  box-shadow: 0px 9px 68px 0px rgba(62, 57, 107, 0.2);
  text-align: center;
  padding: 17px 20px; }
  .youtube .play span {
    color: #774b86; }

/*-----------------------------------
 * CLIENT LOGOS
 *-----------------------------------*/
.client-logos.negative-margin {
  margin-top: -180px; }
  @media (max-width: 991px) {
    .client-logos.negative-margin {
      margin-top: -150px; } }
  @media (max-width: 767px) {
    .client-logos.negative-margin {
      margin-top: -100px; } }
  @media (max-width: 575px) {
    .client-logos.negative-margin {
      margin-top: -70px; } }

@media (min-width: 992px) {
  .client-logos {
    padding-left: 1em;
    padding-right: 1em; } }

@media (max-width: 991px) {
  .client-logos img {
    height: 50px; } }

/*-----------------------------------
 * FEATURES
 *-----------------------------------*/
.browser-window {
  display: inline-block;
  text-align: left;
  min-height: 200px;
  max-width: 100%;
  border-radius: 5px;
  background-color: #F1F3F5;
  box-shadow: 0 19px 38px rgba(137, 149, 199, 0.15), 0 15px 12px rgba(137, 149, 199, 0.08);
  margin-bottom: 60px;
  overflow: hidden; }
  .browser-window.limit-height {
    max-height: 500px; }
  .browser-window .top-bar {
    height: 40px;
    border-radius: 5px 5px 0 0;
    border-top: thin solid #F1F3F5;
    border-bottom: thin solid #F1F3F5;
    background: #f8faff;
    width: 100%; }
  .browser-window .circle {
    height: 13px;
    width: 13px;
    margin-right: 5px;
    display: inline-block;
    border-radius: 50%;
    background-color: #fff; }
  .browser-window .circles {
    margin: 12px 12px; }
  .browser-window .content {
    display: inline-block;
    margin: 0;
    border-radius: 0 0 5px 5px;
    background-color: #fafafa;
    max-height: 500px; }
  .browser-window .content img {
    max-width: 100%;
    height: auto; }
  .browser-window .circle-red {
    background: #FBBBF5; }
  .browser-window .circle-yellow {
    background: #F8E397; }
  .browser-window .circle-blue {
    background: #79CFD5; }

.cast-shadow {
  border-radius: 10px;
  box-shadow: 0px 9px 68px 0px rgba(62, 57, 107, 0.2); }

.media-icon i {
  min-width: 50px;
  color: #774b86; }

.color-icon i {
  color: #774b86; }

/*-----------------------------------
 * CARDS
 *-----------------------------------*/
.card {
  transition: all 0.3s ease; }


.stat-counter {
  color: #fff; }

/*-----------------------------------
 * TWITTER CARDS
 *-----------------------------------*/
.embed_twitter iframe {
  margin: 0 auto !important; }

.embed-tweet-item {
  min-height: 200px;
  margin-bottom: 20px; }

.EmbeddedTweet {
  height: 200px !important; }

.embed-tweet-item {
  width: 100%; }
  .embed-tweet-item blockquote {
    width: 100%;
    min-height: 200px;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAWIAAADLCAMAAABj5mnaAAAAOVBMVEUAAAAAAAAAAAAAAADw8PD+/v7+/v7d7vzf8Pzn8/3q7O3s9v3t7/Dt9v319vb7/f/8/f/+//////8fc5AOAAAAB3RSTlMABwwNdu7v38/aVQAAAoVJREFUeJzt28Fu2kAUQFHbQEJKmyb9/49tFYlNBYgKX1PDOUvLegx3xZjxMIzThsw0DsO43f0i87Idh0nh1G4aNvdew6PbSFyTOCdxTuKcxDmJcxLnJM5JnJM4J3FO4pzEOYlzEufOJf5+jYXXOp/Pw/41sD98nviw50x8ePsoxn68/Thx9TkT75PCfxrvT1x8zsSvSw6eJfG3RcQlssESz0riSyWywRLPSuJLJbLBEs9K4kslssES37xtPjf4SGKJJf4i8anBRxJLLPEXiU8NPpJYYonXSeLc+jbQqyNxTuLcChO/zyX65n+TOCdxTuKcxLkVHrhaW+Lo2ODP8Njg2hKv8PDr2hIvSuLcc+7uFiVxTuKcN5ZyEuckzkmckzgncU7inMQ5iXMS5yTOSZyTOCdxTuKcxDmJcxLnJM6dS7zMGzL/KYlzEuckzkmckzgncU7inMQ5iXMS5yTOSZyTOLdMYmYjcU7inMQ5iXMS5yTO+V18C4lzEuckzkmckzgncU7inMQ5iXMS5yTOSZyTOHdTYmYjcU7inMQ5iXMS5yTOSZx7uK3Hwv2uIHFO4pzEOYlzEuckzkmckzgncU7inMQ5iXMS5zzMzEmckzgncU7inMQ5iXMS5yTOSZyTOCdx7lzi97tbOERH4pzEOYlzEuckzkmckzgncU7inA10TuKcxDmJcxLnJM5JnJM4J3FO4pzEOYlzEuckzv3Dw8wbbrv6vkckcU7inMQ5iXMS5yTOSZyTOCdxTuKcxDmJcxLnJM5JnJM4J3FO4pz/7nIS5yTOSZyTOCdxTuKcxDmJcxLnJM5JnJM4txmm3b3X8Nh20zBuX+69ikf2sh2HYZw2ZKZx+A3lwpMMnJsiQQAAAABJRU5ErkJggg==) no-repeat center top;
    background-size: contain;
    border: none; }

/*-----------------------------------
 * PRICING
 *-----------------------------------*/
.pricing {
  text-align: center;
  padding: 20px;
  border: 0;
  border-radius: 15px;
  margin-bottom: 30px; }
  .pricing small {
    letter-spacing: 2px; }
  .pricing .card-title {
    font-size: 65px;
    font-family: "Product Sans", Helvetica, Arial, serif;
    font-weight: 700;
    margin: 10px 0 15px; }
  .pricing .plan-muted {
    text-decoration: line-through;
    opacity: 0.5; }
  .pricing ul {
    line-height: 2.5;
    margin: 0 0 20px; }

/*-----------------------------------
 * FORM
 *-----------------------------------*/
.form-control {
  height: 50px;
  border-radius: 30px;
  padding: 5px 20px;
  height: 55px;
  border-color: #ebebed;
  box-shadow: 0 0 5px rgba(38, 38, 76, 0.05); }

.btn-block {
  padding: 15px 0; }

/*-----------------------------------
 * CONTACT
 *-----------------------------------*/
#map {
  height: 620px;
  width: 100%; }

.contact-box {
  background: #fff;
  margin-top: -250px;
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.2); }

.address-box {
  padding: 30px;
  color: #fff; }
  .address-box a {
    color: #fff; }

.message-box {
  padding: 30px; }

/*-----------------------------------
 * FOOTER
 *-----------------------------------*/
.footer-links li {
  font-weight: 400;
  padding: 0.2em 0; }

.footer-links a {
  color: #636c72; }
  .footer-links a:hover {
    text-decoration: none;
    color: #774b86; }

/*-----------------------------------
 * BLOG
 *-----------------------------------*/
.post_title {
  font-weight: 300;
  margin: 1em 0 0.4em;
  line-height: 1.4; }

a.title img {
  transition: opacity 0.3s ease; }

a.title:hover img {
  opacity: 0.8; }

.page-link {
  padding: 0.7rem 1.35rem;
  color: #000;
  border: 1px solid #ececec; }
  .page-link:hover, .page-link:focus {
    background-color: #f7f7f7; }
  .page-item.disabled .page-link {
    border-color: #eceeef;
    color: #eceeef; }
  .page-item:first-child .page-link {
    border-radius: 0;
    padding: 5px 5px 4px; }
  .page-item:last-child .page-link {
    border-radius: 0;
    padding: 5px 5px 4px; }

.author-box {
  max-width: 730px;
  margin: 20px auto;
  padding: 1em;
  border: 1px solid #eceeef; }

.entry-content p {
  line-height: 1.7;
  margin-top: 1rem;
  margin-bottom: 2rem; }

.entry-content img {
  max-width: 100%;
  height: auto; }

@media (min-width: 992px) {
  .entry-content img.alignnone {
    max-width: 150%;
    height: auto;
    margin-left: -25%; } }

.alignleft {
  display: inline;
  float: left;
  margin-right: 1.5em; }

.wp-caption-text {
  text-align: center;
  margin: 0.8em 0 1.5em;
  color: #636c72; }

/*Comments*/
.comments-area, .comment-respond {
  max-width: 730px;
  margin: 20px auto; }

.comment .avatar {
  width: 60px;
  margin-right: 1rem; }

.comment ol.children {
  border-left: 2px solid #eceeef; }

.comment-respond {
  border-top: 1px solid #eceeef;
  margin-top: 3em;
  padding-top: 2em; }

/*# sourceMappingURL=style.css.map */

.ui.form .success.message:not(:empty){
  display: block;
}

.ui.steps .step.active .title{
  color: #784987;
}

@font-face {
	font-family: 'Pe-icon-7-stroke';
	src:url(/static/media/Pe-icon-7-stroke.71394c0c.eot);
	src:url(/static/media/Pe-icon-7-stroke.71394c0c.eot?#iefixd7yf1v) format('embedded-opentype'),
		url(/static/media/Pe-icon-7-stroke.b38ef310.woff) format('woff'),
		url(/static/media/Pe-icon-7-stroke.01798bc1.ttf) format('truetype'),
		url(/static/media/Pe-icon-7-stroke.c45f7de0.svg#Pe-icon-7-stroke) format('svg');
	font-weight: normal;
	font-style: normal;
}

[class^="pe-7s-"], [class*=" pe-7s-"] {
	display: inline-block;
	font-family: 'Pe-icon-7-stroke';
	speak: none;
	font-style: normal;
	font-weight: normal;
	-webkit-font-feature-settings: normal;
	        font-feature-settings: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.pe-lg {
  font-size: 1.3333333333333333em;
  line-height: 0.75em;
  vertical-align: -15%;
}
.pe-2x {
  font-size: 2em;
}
.pe-3x {
  font-size: 3em;
}
.pe-4x {
  font-size: 4em;
}
.pe-5x {
  font-size: 5em;
}
.pe-fw {
  width: 1.2857142857142858em;
  text-align: center;
}

.pe-border {
  padding: .2em .25em .15em;
  border: solid 0.08em #eeeeee;
  border-radius: .1em;
}
.pe-inverse {
  color: #ffffff;
}

.pe-va {
  vertical-align: middle;
}

/* icon ref */

.pe-7s-album:before {
	content: "\E6AA";
}
.pe-7s-arc:before {
	content: "\E6AB";
}
.pe-7s-back-2:before {
	content: "\E6AC";
}
.pe-7s-bandaid:before {
	content: "\E6AD";
}
.pe-7s-car:before {
	content: "\E6AE";
}
.pe-7s-diamond:before {
	content: "\E6AF";
}
.pe-7s-door-lock:before {
	content: "\E6B0";
}
.pe-7s-eyedropper:before {
	content: "\E6B1";
}
.pe-7s-female:before {
	content: "\E6B2";
}
.pe-7s-gym:before {
	content: "\E6B3";
}
.pe-7s-hammer:before {
	content: "\E6B4";
}
.pe-7s-headphones:before {
	content: "\E6B5";
}
.pe-7s-helm:before {
	content: "\E6B6";
}
.pe-7s-hourglass:before {
	content: "\E6B7";
}
.pe-7s-leaf:before {
	content: "\E6B8";
}
.pe-7s-magic-wand:before {
	content: "\E6B9";
}
.pe-7s-male:before {
	content: "\E6BA";
}
.pe-7s-map-2:before {
	content: "\E6BB";
}
.pe-7s-next-2:before {
	content: "\E6BC";
}
.pe-7s-paint-bucket:before {
	content: "\E6BD";
}
.pe-7s-pendrive:before {
	content: "\E6BE";
}
.pe-7s-photo:before {
	content: "\E6BF";
}
.pe-7s-piggy:before {
	content: "\E6C0";
}
.pe-7s-plugin:before {
	content: "\E6C1";
}
.pe-7s-refresh-2:before {
	content: "\E6C2";
}
.pe-7s-rocket:before {
	content: "\E6C3";
}
.pe-7s-settings:before {
	content: "\E6C4";
}
.pe-7s-shield:before {
	content: "\E6C5";
}
.pe-7s-smile:before {
	content: "\E6C6";
}
.pe-7s-usb:before {
	content: "\E6C7";
}
.pe-7s-vector:before {
	content: "\E6C8";
}
.pe-7s-wine:before {
	content: "\E6C9";
}
.pe-7s-cloud-upload:before {
	content: "\E68A";
}
.pe-7s-cash:before {
	content: "\E68C";
}
.pe-7s-close:before {
	content: "\E680";
}
.pe-7s-bluetooth:before {
	content: "\E68D";
}
.pe-7s-cloud-download:before {
	content: "\E68B";
}
.pe-7s-way:before {
	content: "\E68E";
}
.pe-7s-close-circle:before {
	content: "\E681";
}
.pe-7s-id:before {
	content: "\E68F";
}
.pe-7s-angle-up:before {
	content: "\E682";
}
.pe-7s-wristwatch:before {
	content: "\E690";
}
.pe-7s-angle-up-circle:before {
	content: "\E683";
}
.pe-7s-world:before {
	content: "\E691";
}
.pe-7s-angle-right:before {
	content: "\E684";
}
.pe-7s-volume:before {
	content: "\E692";
}
.pe-7s-angle-right-circle:before {
	content: "\E685";
}
.pe-7s-users:before {
	content: "\E693";
}
.pe-7s-angle-left:before {
	content: "\E686";
}
.pe-7s-user-female:before {
	content: "\E694";
}
.pe-7s-angle-left-circle:before {
	content: "\E687";
}
.pe-7s-up-arrow:before {
	content: "\E695";
}
.pe-7s-angle-down:before {
	content: "\E688";
}
.pe-7s-switch:before {
	content: "\E696";
}
.pe-7s-angle-down-circle:before {
	content: "\E689";
}
.pe-7s-scissors:before {
	content: "\E697";
}
.pe-7s-wallet:before {
	content: "\E600";
}
.pe-7s-safe:before {
	content: "\E698";
}
.pe-7s-volume2:before {
	content: "\E601";
}
.pe-7s-volume1:before {
	content: "\E602";
}
.pe-7s-voicemail:before {
	content: "\E603";
}
.pe-7s-video:before {
	content: "\E604";
}
.pe-7s-user:before {
	content: "\E605";
}
.pe-7s-upload:before {
	content: "\E606";
}
.pe-7s-unlock:before {
	content: "\E607";
}
.pe-7s-umbrella:before {
	content: "\E608";
}
.pe-7s-trash:before {
	content: "\E609";
}
.pe-7s-tools:before {
	content: "\E60A";
}
.pe-7s-timer:before {
	content: "\E60B";
}
.pe-7s-ticket:before {
	content: "\E60C";
}
.pe-7s-target:before {
	content: "\E60D";
}
.pe-7s-sun:before {
	content: "\E60E";
}
.pe-7s-study:before {
	content: "\E60F";
}
.pe-7s-stopwatch:before {
	content: "\E610";
}
.pe-7s-star:before {
	content: "\E611";
}
.pe-7s-speaker:before {
	content: "\E612";
}
.pe-7s-signal:before {
	content: "\E613";
}
.pe-7s-shuffle:before {
	content: "\E614";
}
.pe-7s-shopbag:before {
	content: "\E615";
}
.pe-7s-share:before {
	content: "\E616";
}
.pe-7s-server:before {
	content: "\E617";
}
.pe-7s-search:before {
	content: "\E618";
}
.pe-7s-film:before {
	content: "\E6A5";
}
.pe-7s-science:before {
	content: "\E619";
}
.pe-7s-disk:before {
	content: "\E6A6";
}
.pe-7s-ribbon:before {
	content: "\E61A";
}
.pe-7s-repeat:before {
	content: "\E61B";
}
.pe-7s-refresh:before {
	content: "\E61C";
}
.pe-7s-add-user:before {
	content: "\E6A9";
}
.pe-7s-refresh-cloud:before {
	content: "\E61D";
}
.pe-7s-paperclip:before {
	content: "\E69C";
}
.pe-7s-radio:before {
	content: "\E61E";
}
.pe-7s-note2:before {
	content: "\E69D";
}
.pe-7s-print:before {
	content: "\E61F";
}
.pe-7s-network:before {
	content: "\E69E";
}
.pe-7s-prev:before {
	content: "\E620";
}
.pe-7s-mute:before {
	content: "\E69F";
}
.pe-7s-power:before {
	content: "\E621";
}
.pe-7s-medal:before {
	content: "\E6A0";
}
.pe-7s-portfolio:before {
	content: "\E622";
}
.pe-7s-like2:before {
	content: "\E6A1";
}
.pe-7s-plus:before {
	content: "\E623";
}
.pe-7s-left-arrow:before {
	content: "\E6A2";
}
.pe-7s-play:before {
	content: "\E624";
}
.pe-7s-key:before {
	content: "\E6A3";
}
.pe-7s-plane:before {
	content: "\E625";
}
.pe-7s-joy:before {
	content: "\E6A4";
}
.pe-7s-photo-gallery:before {
	content: "\E626";
}
.pe-7s-pin:before {
	content: "\E69B";
}
.pe-7s-phone:before {
	content: "\E627";
}
.pe-7s-plug:before {
	content: "\E69A";
}
.pe-7s-pen:before {
	content: "\E628";
}
.pe-7s-right-arrow:before {
	content: "\E699";
}
.pe-7s-paper-plane:before {
	content: "\E629";
}
.pe-7s-delete-user:before {
	content: "\E6A7";
}
.pe-7s-paint:before {
	content: "\E62A";
}
.pe-7s-bottom-arrow:before {
	content: "\E6A8";
}
.pe-7s-notebook:before {
	content: "\E62B";
}
.pe-7s-note:before {
	content: "\E62C";
}
.pe-7s-next:before {
	content: "\E62D";
}
.pe-7s-news-paper:before {
	content: "\E62E";
}
.pe-7s-musiclist:before {
	content: "\E62F";
}
.pe-7s-music:before {
	content: "\E630";
}
.pe-7s-mouse:before {
	content: "\E631";
}
.pe-7s-more:before {
	content: "\E632";
}
.pe-7s-moon:before {
	content: "\E633";
}
.pe-7s-monitor:before {
	content: "\E634";
}
.pe-7s-micro:before {
	content: "\E635";
}
.pe-7s-menu:before {
	content: "\E636";
}
.pe-7s-map:before {
	content: "\E637";
}
.pe-7s-map-marker:before {
	content: "\E638";
}
.pe-7s-mail:before {
	content: "\E639";
}
.pe-7s-mail-open:before {
	content: "\E63A";
}
.pe-7s-mail-open-file:before {
	content: "\E63B";
}
.pe-7s-magnet:before {
	content: "\E63C";
}
.pe-7s-loop:before {
	content: "\E63D";
}
.pe-7s-look:before {
	content: "\E63E";
}
.pe-7s-lock:before {
	content: "\E63F";
}
.pe-7s-lintern:before {
	content: "\E640";
}
.pe-7s-link:before {
	content: "\E641";
}
.pe-7s-like:before {
	content: "\E642";
}
.pe-7s-light:before {
	content: "\E643";
}
.pe-7s-less:before {
	content: "\E644";
}
.pe-7s-keypad:before {
	content: "\E645";
}
.pe-7s-junk:before {
	content: "\E646";
}
.pe-7s-info:before {
	content: "\E647";
}
.pe-7s-home:before {
	content: "\E648";
}
.pe-7s-help2:before {
	content: "\E649";
}
.pe-7s-help1:before {
	content: "\E64A";
}
.pe-7s-graph3:before {
	content: "\E64B";
}
.pe-7s-graph2:before {
	content: "\E64C";
}
.pe-7s-graph1:before {
	content: "\E64D";
}
.pe-7s-graph:before {
	content: "\E64E";
}
.pe-7s-global:before {
	content: "\E64F";
}
.pe-7s-gleam:before {
	content: "\E650";
}
.pe-7s-glasses:before {
	content: "\E651";
}
.pe-7s-gift:before {
	content: "\E652";
}
.pe-7s-folder:before {
	content: "\E653";
}
.pe-7s-flag:before {
	content: "\E654";
}
.pe-7s-filter:before {
	content: "\E655";
}
.pe-7s-file:before {
	content: "\E656";
}
.pe-7s-expand1:before {
	content: "\E657";
}
.pe-7s-exapnd2:before {
	content: "\E658";
}
.pe-7s-edit:before {
	content: "\E659";
}
.pe-7s-drop:before {
	content: "\E65A";
}
.pe-7s-drawer:before {
	content: "\E65B";
}
.pe-7s-download:before {
	content: "\E65C";
}
.pe-7s-display2:before {
	content: "\E65D";
}
.pe-7s-display1:before {
	content: "\E65E";
}
.pe-7s-diskette:before {
	content: "\E65F";
}
.pe-7s-date:before {
	content: "\E660";
}
.pe-7s-cup:before {
	content: "\E661";
}
.pe-7s-culture:before {
	content: "\E662";
}
.pe-7s-crop:before {
	content: "\E663";
}
.pe-7s-credit:before {
	content: "\E664";
}
.pe-7s-copy-file:before {
	content: "\E665";
}
.pe-7s-config:before {
	content: "\E666";
}
.pe-7s-compass:before {
	content: "\E667";
}
.pe-7s-comment:before {
	content: "\E668";
}
.pe-7s-coffee:before {
	content: "\E669";
}
.pe-7s-cloud:before {
	content: "\E66A";
}
.pe-7s-clock:before {
	content: "\E66B";
}
.pe-7s-check:before {
	content: "\E66C";
}
.pe-7s-chat:before {
	content: "\E66D";
}
.pe-7s-cart:before {
	content: "\E66E";
}
.pe-7s-camera:before {
	content: "\E66F";
}
.pe-7s-call:before {
	content: "\E670";
}
.pe-7s-calculator:before {
	content: "\E671";
}
.pe-7s-browser:before {
	content: "\E672";
}
.pe-7s-box2:before {
	content: "\E673";
}
.pe-7s-box1:before {
	content: "\E674";
}
.pe-7s-bookmarks:before {
	content: "\E675";
}
.pe-7s-bicycle:before {
	content: "\E676";
}
.pe-7s-bell:before {
	content: "\E677";
}
.pe-7s-battery:before {
	content: "\E678";
}
.pe-7s-ball:before {
	content: "\E679";
}
.pe-7s-back:before {
	content: "\E67A";
}
.pe-7s-attention:before {
	content: "\E67B";
}
.pe-7s-anchor:before {
	content: "\E67C";
}
.pe-7s-albums:before {
	content: "\E67D";
}
.pe-7s-alarm:before {
	content: "\E67E";
}
.pe-7s-airplay:before {
	content: "\E67F";
}

.home-container .intro-container {
  padding-top: 20px;
  padding-right: 26px !important;
}
.home-container .intro-container .intro-text {
  color: #6d6f71 !important;
}
.home-container .intro-container .img-fluid {
    width: 100%;
}
.home-container .intro-container .btn-get-started {
    background-image: linear-gradient(90deg, #774b86, #bea6d4) !important;
    padding: 16px 35px !important;
    border-radius: 30px !important;
    color: white !important;
}
.home-container .intro-container .btn-get-started:hover {
      background-image: linear-gradient(90deg, #bea6d4, #774b86) !important;
}
@media only screen and (max-width: 784px) {
  .home-container .intro-container {
        padding-top: 120px;
  }
}
.home-container .brand-container {
  margin-top: 150px !important;
  padding-right: 26px !important;
}
.home-container .feature-container {
  background-color: #f8f9fa !important;
  margin-top: 30px !important;
  padding-right: 26px !important;
}
.home-container .feature-container .feature-column {
    padding: 20px 10px;
}
.home-container .feature-container .feature-column .media-icon {
      display: inline-block !important;
      width: 20% !important;
      vertical-align: top !important;
}
.home-container .feature-container .feature-column .media-body {
      display: inline-block !important;
      width: 80% !important;
}
.home-container .mobile-container {
  margin-top: 80px !important;
  padding-right: 26px !important;
}
.home-container .mobile-container .media-body {
    padding-left: 15px;
}
.home-container .chart-container {
  margin-top: 80px !important;
  padding-right: 26px !important;
}
.home-container .feature2-container {
  background-color: #f8f9fa !important;
  margin-top: 30px !important;
  padding-right: 26px !important;
}
.home-container .feature2-container .container {
    padding-top: 50px;
    padding-bottom: 50px;
}
.home-container .feature2-container .container .feature-column {
      text-align: center;
}
.home-container .feature2-container .container .feature-column .media-icon {
        text-align: center;
}
.home-container .feature2-container .container .feature-column .media-body {
        text-align: center;
}
.home-container .twitter-container {
  margin-top: 80px !important;
  padding-right: 26px !important;
}
.home-container .end-button-container {
  margin-top: 80px !important;
  margin-bottom: 80px !important;
}
.home-container .end-button-container .btn-get-started {
    background-image: linear-gradient(90deg, #774b86, #bea6d4) !important;
    padding: 20px 120px !important;
    border-radius: 30px !important;
    color: white !important;
    font-size: 1.1rem;
}

.home-container .end-button-container .btn-get-started:hover {
      background-image: linear-gradient(90deg, #bea6d4, #774b86) !important;
}

@media only screen and (max-width: 784px) {
  .home-container .end-button-container {
        margin-top: 0 !important;
        margin-bottom: 40px !important;
  }
  .home-container .end-button-container .btn-get-started {
          background-image: linear-gradient(90deg, #774b86, #bea6d4) !important;
          padding: 20px 80px !important;
          border-radius: 30px !important;
          color: white !important;
          font-size: 1.1rem;
  }
}

/*# sourceMappingURL=home.css.map */

h4.permission-message {
  color: red;
  text-align: center;
  position: absolute;
  top: 6%;
  width: 100%
}

h2.home-header {
  text-shadow: 2px 3px 6px #ccc;
}

.mobile-app-download {
  margin-left: 10px;
  margin-right: 10px;
  width: 35%;
}

.centered-image{
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.intro{
    font-size: 18px;
    color:#484848;
    margin-top: 30px;
    margin-bottom: 40px;
}

.upload-button{
	width: 100%;
}

.signup-container-inner {
  position: absolute;
  top: 37%;
  left: 50%;
  -webkit-transform: translate(-50%, -35%);
          transform: translate(-50%, -35%);
}

.top-to-bottom {
  border-width: 3px;
  border-style: solid;
  border-image:
    linear-gradient(to bottom, black, rgba(0, 0, 0, 0)) 1 100%;
}

@media only screen and (max-width: 767px) {
  .ui.form .fields {
    flex-wrap: inherit !important;
  }
}

.recaptcha-custom {
  display: -webkit-inline-box;
  margin-top: 20px;
  min-height: 40px;
}

.location-search-input{
    width: 300px;
    height: 40px;
    padding: 2px 5px;
    text-align: center;
}

.suggestion-item, .suggestion-item-active{
    min-height: 30px;
    line-height: 30px;
}

.autocomplete-dropdown-container{
    width: 300px;
    margin: 0 auto;
    float: none;
}

div[id='^PlacesAutocomplete__suggestion']{
    min-height: 30px;
    line-height: 30px;
}
.secondary-fixed-top-menu{
    top:60px !important;
}

.filter-menu-item{
    padding: 1px !important;
}

.filter-menu-item.location{
    min-width: 147px !important;
}

.filter-menu-item.radius,.filter-menu-item.type{
    min-width: 110px !important;
}

.filter-menu-item.clear-button{
    min-width: 158px !important;
}

.filter-menu-item.search-input{
    min-width: 158px !important;
}

.filter-menu-item.search-input input{
    min-width: 157px !important;
}

.filter-menu-item.search-input .field{
    margin-bottom: 0 !important;
}

.filter-menu-item .dropdown{
    border: 0 !important;
    box-shadow: none !important;
}

.filter-menu-item .button{
    padding: 1px;
}

.job-title{
    color: rgba(0,0,0,.87) !important;
}

.item-client-name{
    margin-top: 2px !important;
    margin-bottom: 5px !important;
}

.status-button{
    padding: .35em 1.5em .35em !important;
}

.Cal__Container__root{
    box-shadow: 0 1px 2px 0 rgba(34,36,38,.15);
}

.job-title{
    margin-left:10px;
}


/*Mobile */
@media only screen and (max-width: 767px) {
    .filter-menu-item.clear-button{
        height: 44px;
    }
    .filter-menu-item.clear-button button{
        width: 100% !important;
    }

    .search-form{
        width: 100% !important;
    }
}

.box {
  box-shadow: 0 0 0 0 rgba(34,36,38,.15) !important;
}
.day-text {
  color: #666 !important;
  margin: 0px !important;
}

.date-text {
  color: #000 !important;
  margin: 0px !important;
  line-height: 1.28571429em !important;
}

.month-text {
  color: #f00 !important;
  margin: 0px !important;
  /* line-height: 1.28571429em !important; */
}

.mission-container {
    padding: 80px 0 !important;
    background: #f6eef9 !important;
    margin-top: -2em;
}

.mission-feature-container {
  margin-bottom: 100px;
}

.mission-feature-container .post_title {
    text-align: center;
}

.mission-feature-container .post-meta {
    text-align: center;
    color: #868e96 !important;
}

.mission-feature-container .post-meta a {
    color: #868e96 !important;
}

.mission-feature-container .post-desc {
    text-align: center;
}

.mission-feature-container .continue-reading {
    display: block;
    width: 100%;
    text-align: center;
}

/*# sourceMappingURL=mission.css.map */

.react-timezone-container {
  display: inline-block;
  font: 13px sans-serif;
  position: relative;
}

.react-timezone-ul::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.react-timezone-ul::-webkit-scrollbar-thumb {
  background: #888;
}

.react-timezone-input {
  width: 100%;
  padding: 9px 12px;
  font: inherit;
  box-sizing: border-box;
  outline: 0;
  background: #fff;
  border: 1px solid #e6ebec;
  border-radius: 2px;
  color: #474747;
}
.react-timezone-ul {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  max-height: 200px;
  overflow-y: auto;
  margin: 0;
  padding: 0;
  border: 1px solid #e6ebec;
  margin-top: -1px;
  border-radius: 0 0 3px 3px;
  display: none;
  z-index: 999999 !important;
}
.react-timezone-li {
  display: block;
}
.react-timezone-button {
  color: #444;
  padding: 5px 12px;
  cursor: pointer;
  outline: none;
  display: block;
  border: 0;
  width: 100%;
  text-align: left;
  border-radius: 0;
  font: inherit;
  background: #f9f8f8;
}
.react-timezone-button.focus {
  background: #f0f0f0;
}
.react-timezone-ul.open {
  display: block;
}
.react-timezone-input::-ms-clear {
  display: none;
}

.role-modal {
  width: 95% !important;
}

.role-modal .dnd-calendar-container {
  height: 88vh;
  overflow-y: auto;
}

.role-modal .Cal__Container__root {
  /* margin-top: 13px !important;  */
}

.role-modal .rbc-toolbar {
  margin-bottom: 0 !important;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: #774a85;
  color: #fff;
}

.editable_text_area {
  margin: 0;
  -webkit-appearance: none;
  tap-highlight-color: rgba(255,255,255,0);
  padding: .78571429em 1em;
  background: #fff;
  border: 1px solid rgba(34,36,38,.15);
  outline: 0;
  color: rgba(0,0,0,.87);
  border-radius: .28571429rem;
  box-shadow: 0 0 0 0 transparent inset;
  transition: color .1s ease,border-color .1s ease;
  font-size: 1em;
  line-height: 1.2857;
  resize: vertical;
}

.bold-title {
  color: #ccc !important;
  font-size: medium !important;;
}
.bold-title:hover {
  color: #ccc !important;
}
/*# sourceMappingURL=create-event.css.map */

.rbc-addons-dnd .rbc-row-content {
  pointer-events: none;
}
.rbc-addons-dnd .rbc-row-content .rbc-show-more,
.rbc-addons-dnd .rbc-row-content .rbc-event {
  pointer-events: all;
}
.rbc-addons-dnd .rbc-addons-dnd-over {
  background-color: rgba(0, 0, 0, 0.3);
}
.rbc-addons-dnd .rbc-events-container {
  pointer-events: none;
}
.rbc-addons-dnd .rbc-event {
  transition: opacity 150ms;
  pointer-events: all;
}
.rbc-addons-dnd .rbc-event:hover .rbc-addons-dnd-resize-ns-icon,
.rbc-addons-dnd .rbc-event:hover .rbc-addons-dnd-resize-ew-icon {
  display: block;
}
.rbc-addons-dnd.rbc-addons-dnd-is-dragging .rbc-event {
  pointer-events: none;
  opacity: .50;
}
.rbc-addons-dnd .rbc-addons-dnd-resizable {
  position: relative;
  width: 100%;
  height: 100%;
}
.rbc-addons-dnd .rbc-addons-dnd-resize-ns-anchor {
  width: 100%;
  text-align: center;
  position: absolute;
}
.rbc-addons-dnd .rbc-addons-dnd-resize-ns-anchor:first-child {
  top: 0;
}
.rbc-addons-dnd .rbc-addons-dnd-resize-ns-anchor:last-child {
  bottom: 0;
}
.rbc-addons-dnd .rbc-addons-dnd-resize-ns-anchor .rbc-addons-dnd-resize-ns-icon {
  display: none;
  border-top: 3px double;
  margin: 0 auto;
  width: 10px;
  cursor: ns-resize;
}
.rbc-addons-dnd .rbc-addons-dnd-resize-ew-anchor {
  position: absolute;
  top: 4px;
  bottom: 0;
}
.rbc-addons-dnd .rbc-addons-dnd-resize-ew-anchor:first-child {
  left: 0;
}
.rbc-addons-dnd .rbc-addons-dnd-resize-ew-anchor:last-child {
  right: 0;
}
.rbc-addons-dnd .rbc-addons-dnd-resize-ew-anchor .rbc-addons-dnd-resize-ew-icon {
  display: none;
  border-left: 3px double;
  margin-top: auto;
  margin-bottom: auto;
  height: 10px;
  cursor: ew-resize;
}

.rbc-btn {
  color: inherit;
  font: inherit;
  margin: 0;
}
button.rbc-btn {
  overflow: visible;
  text-transform: none;
  -webkit-appearance: button;
  cursor: pointer;
}
button[disabled].rbc-btn {
  cursor: not-allowed;
}
button.rbc-input::-moz-focus-inner {
  border: 0;
  padding: 0;
}
.rbc-calendar {
  box-sizing: border-box;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}
.rbc-calendar *,
.rbc-calendar *:before,
.rbc-calendar *:after {
  box-sizing: inherit;
}
.rbc-abs-full,
.rbc-row-bg {
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.rbc-ellipsis,
.rbc-event-label,
.rbc-row-segment .rbc-event-content,
.rbc-show-more {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.rbc-rtl {
  direction: rtl;
}
.rbc-off-range {
  color: #999999;
}
.rbc-off-range-bg {
  background: #e5e5e5;
}
.rbc-header {
  overflow: hidden;
  flex: 1 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0 3px;
  text-align: center;
  vertical-align: middle;
  font-weight: bold;
  font-size: 90%;
  min-height: 0;
  border-bottom: 1px solid #DDD;
}
.rbc-header + .rbc-header {
  border-left: 1px solid #DDD;
}
.rbc-rtl .rbc-header + .rbc-header {
  border-left-width: 0;
  border-right: 1px solid #DDD;
}
.rbc-header > a,
.rbc-header > a:active,
.rbc-header > a:visited {
  color: inherit;
  text-decoration: none;
}
.rbc-row-content {
  position: relative;
  -moz-user-select: none;
   -ms-user-select: none;
       user-select: none;
  -webkit-user-select: none;
  z-index: 4;
}
.rbc-today {
  background-color: #eaf6ff;
}
.rbc-toolbar {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-size: 16px;
}
.rbc-toolbar .rbc-toolbar-label {
  flex-grow: 1;
  padding: 0 10px;
  text-align: center;
}
.rbc-toolbar button {
  color: #373a3c;
  display: inline-block;
  margin: 0;
  text-align: center;
  vertical-align: middle;
  background: none;
  background-image: none;
  border: 1px solid #ccc;
  padding: .375rem 1rem;
  border-radius: 4px;
  line-height: normal;
  white-space: nowrap;
}
.rbc-toolbar button:active,
.rbc-toolbar button.rbc-active {
  background-image: none;
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  background-color: #e6e6e6;
  border-color: #adadad;
}
.rbc-toolbar button:active:hover,
.rbc-toolbar button.rbc-active:hover,
.rbc-toolbar button:active:focus,
.rbc-toolbar button.rbc-active:focus {
  color: #373a3c;
  background-color: #d4d4d4;
  border-color: #8c8c8c;
}
.rbc-toolbar button:focus {
  color: #373a3c;
  background-color: #e6e6e6;
  border-color: #adadad;
}
.rbc-toolbar button:hover {
  color: #373a3c;
  background-color: #e6e6e6;
  border-color: #adadad;
}
.rbc-btn-group {
  display: inline-block;
  white-space: nowrap;
}
.rbc-btn-group > button:first-child:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.rbc-btn-group > button:last-child:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.rbc-rtl .rbc-btn-group > button:first-child:not(:last-child) {
  border-radius: 4px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.rbc-rtl .rbc-btn-group > button:last-child:not(:first-child) {
  border-radius: 4px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.rbc-btn-group > button:not(:first-child):not(:last-child) {
  border-radius: 0;
}
.rbc-btn-group button + button {
  margin-left: -1px;
}
.rbc-rtl .rbc-btn-group button + button {
  margin-left: 0;
  margin-right: -1px;
}
.rbc-btn-group + .rbc-btn-group,
.rbc-btn-group + button {
  margin-left: 10px;
}
.rbc-event {
  padding: 2px 5px;
  background-color: #3174ad;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
}
.rbc-slot-selecting .rbc-event {
  cursor: inherit;
  pointer-events: none;
}
.rbc-event.rbc-selected {
  background-color: #265985;
}
.rbc-event-label {
  font-size: 80%;
}
.rbc-event-overlaps {
  box-shadow: -1px 1px 5px 0px rgba(51, 51, 51, 0.5);
}
.rbc-event-continues-prior {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.rbc-event-continues-after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.rbc-event-continues-earlier {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.rbc-event-continues-later {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.rbc-event-continues-day-after {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.rbc-event-continues-day-prior {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.rbc-row {
  display: flex;
  flex-direction: row;
}
.rbc-row-segment {
  padding: 0 1px 1px 1px;
}
.rbc-selected-cell {
  background-color: rgba(0, 0, 0, 0.1);
}
.rbc-show-more {
  background-color: rgba(255, 255, 255, 0.3);
  z-index: 4;
  font-weight: bold;
  font-size: 85%;
  height: auto;
  line-height: normal;
  white-space: nowrap;
}
.rbc-month-view {
  position: relative;
  border: 1px solid #DDD;
  display: flex;
  flex-direction: column;
  flex: 1 0;
  width: 100%;
  -moz-user-select: none;
   -ms-user-select: none;
       user-select: none;
  -webkit-user-select: none;
  height: 100%;
}
.rbc-month-header {
  display: flex;
  flex-direction: row;
}
.rbc-month-row {
  display: flex;
  position: relative;
  flex-direction: column;
  flex: 1 0;
  flex-basis: 0px;
  overflow: hidden;
  height: 100%;
}
.rbc-month-row + .rbc-month-row {
  border-top: 1px solid #DDD;
}
.rbc-date-cell {
  flex: 1 1;
  min-width: 0;
  padding-right: 5px;
  text-align: right;
}
.rbc-date-cell.rbc-now {
  font-weight: bold;
}
.rbc-date-cell > a,
.rbc-date-cell > a:active,
.rbc-date-cell > a:visited {
  color: inherit;
  text-decoration: none;
}
.rbc-row-bg {
  display: flex;
  flex-direction: row;
  flex: 1 0;
  overflow: hidden;
}
.rbc-day-bg {
  flex: 1 0;
}
.rbc-day-bg + .rbc-day-bg {
  border-left: 1px solid #DDD;
}
.rbc-rtl .rbc-day-bg + .rbc-day-bg {
  border-left-width: 0;
  border-right: 1px solid #DDD;
}
.rbc-overlay {
  position: absolute;
  z-index: 5;
  border: 1px solid #e5e5e5;
  background-color: #fff;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.25);
  padding: 10px;
}
.rbc-overlay > * + * {
  margin-top: 1px;
}
.rbc-overlay-header {
  border-bottom: 1px solid #e5e5e5;
  margin: -10px -10px 5px -10px;
  padding: 2px 10px;
}
.rbc-agenda-view {
  display: flex;
  flex-direction: column;
  flex: 1 0;
  overflow: auto;
}
.rbc-agenda-view table.rbc-agenda-table {
  width: 100%;
  border: 1px solid #DDD;
  border-spacing: 0;
  border-collapse: collapse;
}
.rbc-agenda-view table.rbc-agenda-table tbody > tr > td {
  padding: 5px 10px;
  vertical-align: top;
}
.rbc-agenda-view table.rbc-agenda-table .rbc-agenda-time-cell {
  padding-left: 15px;
  padding-right: 15px;
  text-transform: lowercase;
}
.rbc-agenda-view table.rbc-agenda-table tbody > tr > td + td {
  border-left: 1px solid #DDD;
}
.rbc-rtl .rbc-agenda-view table.rbc-agenda-table tbody > tr > td + td {
  border-left-width: 0;
  border-right: 1px solid #DDD;
}
.rbc-agenda-view table.rbc-agenda-table tbody > tr + tr {
  border-top: 1px solid #DDD;
}
.rbc-agenda-view table.rbc-agenda-table thead > tr > th {
  padding: 3px 5px;
  text-align: left;
  border-bottom: 1px solid #DDD;
}
.rbc-rtl .rbc-agenda-view table.rbc-agenda-table thead > tr > th {
  text-align: right;
}
.rbc-agenda-time-cell {
  text-transform: lowercase;
}
.rbc-agenda-time-cell .rbc-continues-after:after {
  content: ' \BB';
}
.rbc-agenda-time-cell .rbc-continues-prior:before {
  content: '\AB   ';
}
.rbc-agenda-date-cell,
.rbc-agenda-time-cell {
  white-space: nowrap;
}
.rbc-agenda-event-cell {
  width: 100%;
}
.rbc-time-column {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}
.rbc-time-column .rbc-timeslot-group {
  flex: 1 1;
}
.rbc-timeslot-group {
  border-bottom: 1px solid #DDD;
  min-height: 40px;
  display: flex;
  flex-flow: column nowrap;
}
.rbc-time-gutter,
.rbc-header-gutter {
  flex: none;
}
.rbc-label {
  padding: 0 5px;
}
.rbc-day-slot {
  position: relative;
}
.rbc-day-slot .rbc-events-container {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 10px;
  top: 0;
}
.rbc-day-slot .rbc-events-container.rbc-is-rtl {
  left: 10px;
  right: 0;
}
.rbc-day-slot .rbc-event {
  border: 1px solid #265985;
  display: flex;
  max-height: 100%;
  min-height: 20px;
  flex-flow: column wrap;
  align-items: flex-start;
  overflow: hidden;
  position: absolute;
}
.rbc-day-slot .rbc-event-label {
  flex: none;
  padding-right: 5px;
  width: auto;
}
.rbc-day-slot .rbc-event-content {
  width: 100%;
  flex: 1 1;
  word-wrap: break-word;
  line-height: 1;
  height: 100%;
  min-height: 1em;
}
.rbc-day-slot .rbc-time-slot {
  border-top: 1px solid #f7f7f7;
}
.rbc-time-slot {
  flex: 1 0;
}
.rbc-time-slot.rbc-now {
  font-weight: bold;
}
.rbc-day-header {
  text-align: center;
}
.rbc-slot-selection {
  z-index: 10;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  font-size: 75%;
  width: 100%;
  padding: 3px;
}
.rbc-slot-selecting {
  cursor: move;
}
.rbc-time-view {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  width: 100%;
  border: 1px solid #DDD;
  min-height: 0;
}
.rbc-time-view .rbc-time-gutter {
  white-space: nowrap;
}
.rbc-time-view .rbc-allday-cell {
  box-sizing: content-box;
  width: 100%;
  position: relative;
}
.rbc-time-view .rbc-allday-events {
  position: relative;
  z-index: 4;
}
.rbc-time-view .rbc-row {
  box-sizing: border-box;
  min-height: 20px;
}
.rbc-time-header {
  display: flex;
  flex: 0 0 auto;
  flex-direction: row;
}
.rbc-time-header.rbc-overflowing {
  border-right: 1px solid #DDD;
}
.rbc-rtl .rbc-time-header.rbc-overflowing {
  border-right-width: 0;
  border-left: 1px solid #DDD;
}
.rbc-time-header > .rbc-row:first-child {
  border-bottom: 1px solid #DDD;
}
.rbc-time-header > .rbc-row.rbc-row-resource {
  border-bottom: 1px solid #DDD;
}
.rbc-time-header-content {
  flex: 1 1;
  min-width: 0;
  flex-direction: column;
  border-left: 1px solid #DDD;
}
.rbc-rtl .rbc-time-header-content {
  border-left-width: 0;
  border-right: 1px solid #DDD;
}
.rbc-time-content {
  display: flex;
  flex: 1 0;
  align-items: flex-start;
  width: 100%;
  border-top: 2px solid #DDD;
  overflow-y: auto;
  position: relative;
}
.rbc-time-content > .rbc-time-gutter {
  flex: none;
}
.rbc-time-content > * + * > * {
  border-left: 1px solid #DDD;
}
.rbc-rtl .rbc-time-content > * + * > * {
  border-left-width: 0;
  border-right: 1px solid #DDD;
}
.rbc-time-content > .rbc-day-slot {
  width: 100%;
  -moz-user-select: none;
   -ms-user-select: none;
       user-select: none;
  -webkit-user-select: none;
}
.rbc-current-time-indicator {
  position: absolute;
  z-index: 3;
  height: 1px;
  background-color: #74ad31;
  pointer-events: none;
}

@media only screen and (min-width: 576px) {
    .event-signup-container {
        width: 577px !important;
    }

    ui.modal{
        width: 60% !important;
    }
}

.modal{
    margin-top: 0px !important;
}

.modal > .content{
    /* width: 70% !important; */
    float: none !important;
    margin: 0 auto !important;
}

.modal .content .field{
    display: inline;
}

.modal .content .field .input{
    width: 100%;
}

.rbc-toolbar .rbc-btn-group{
    display: none;
}

.react-tagsinput{
    margin-bottom: 10px;
}

.field-error{
    margin-top: 0 !important;
}

.role-checkbox{
    padding: 5px !important;
    height: 45px !important;
}

.role-radio{
    padding: 10px !important;
}

.role-radio label{
    color: white !important;
}

.step1-segment .ui.form .field{
    margin-bottom:0 !important;
}

.Cal__Day__root.Cal__Day__selected .Cal__Day__selection {
    /*background-color: rgb(138, 84, 156) !important;*/
}

.step1-primary-info-row{
    padding-bottom: 0 !important;
}

.grid-event-locations{
     margin: 0 !important;
}

.role-cell{
  padding-left:2px !important;
  padding-right:2px !important;
}

/*Google location css: start*/
.google-loc-search-input-container {
    position: relative;
}

.google-loc-search-input-container input {
    padding: 10px !important;
}

.google-loc-search-input,
.google-loc-search-input:focus,
.google-loc-search-input:active {
    box-shadow: 0 2px 2px 0 rgba(0,0,0,0.16), 0 0 0 1px rgba(0,0,0,0.08);
    border: honeydew;
    display: block;
    width: 100%;
    padding: 16px;
    font-size: 16px;
    border-radius: 2px;
    outline: none;
}

.google-loc-clear-button,
.google-loc-clear-button:active,
.google-loc-clear-button:focus {
    position: absolute;
    right: 16px;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    background: transparent;
    border: none;
    outline: none;
    font-weight: 600;
    color: #999;
}

.google-loc-autocomplete-container {
    border-bottom: honeydew;
    border-left: honeydew;
    border-right: honeydew;
    border-top: 1px solid #e6e6e6;
    box-shadow: 0 2px 4px rgba(0,0,0,0.2);
    border-radius: 0 0 2px 2px;
}

.google-loc-suggestion-item {
    padding: 8px;
    text-align: left;
    background-color: #fff;
    cursor: pointer;
}

.google-loc-suggestion-item--active {
    background-color: #fafafa;
}
/*Google location css: end*/

/*React Tags CSS: start*/
.react-tags {
    position: relative;
    padding: 6px 0 0 6px;
    border: 1px solid #D1D1D1;
    border-radius: 1px;

    /* shared font styles */
    font-size: 1em;
    line-height: 1.2;

    /* clicking anywhere will focus the input */
    cursor: text;
    margin-top: 10px !important;
}

.react-tags.is-focused {
    border-color: #B1B1B1;
}

.react-tags__selected {
    display: inline;
}

.react-tags__selected-tag {
    display: inline-block;
    box-sizing: border-box;
    margin: 0 6px 6px 0;
    padding: 6px 8px;
    border: 1px solid #D1D1D1;
    border-radius: 2px;
    background: #F1F1F1;

    /* match the font styles */
    font-size: inherit;
    line-height: inherit;
}

.react-tags__selected-tag:after {
    content: '\2715';
    color: #AAA;
    margin-left: 8px;
}

.react-tags__selected-tag:hover,
.react-tags__selected-tag:focus {
    border-color: #B1B1B1;
}

.react-tags__search {
    display: inline-block;

    /* match tag layout */
    padding: 7px 2px;
    margin-bottom: 6px;

    /* prevent autoresize overflowing the container */
    max-width: 100%;
}

@media screen and (min-width: 30em) {

    .react-tags__search {
        /* this will become the offsetParent for suggestions */
        position: relative;
    }

}

.react-tags__search input {
    /* prevent autoresize overflowing the container */
    max-width: 100%;

    /* remove styles and layout from this element */
    margin: 0;
    padding: 0;
    border: 0;
    outline: none;

    /* match the font styles */
    font-size: inherit;
    line-height: inherit;
}

.react-tags__search input::-ms-clear {
    display: none;
}

.react-tags__suggestions {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    z-index: 1000000;
}

@media screen and (min-width: 30em) {

    .react-tags__suggestions {
        width: 240px;
    }

}

.react-tags__suggestions ul {
    margin: 4px -1px;
    padding: 0;
    list-style: none;
    background: white;
    border: 1px solid #D1D1D1;
    border-radius: 2px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
}

.react-tags__suggestions li {
    border-bottom: 1px solid #ddd;
    padding: 6px 8px;
}

.react-tags__suggestions li mark {
    text-decoration: underline;
    background: none;
    font-weight: 600;
}

.react-tags__suggestions li:hover {
    cursor: pointer;
    background: #eee;
}

.react-tags__suggestions li.is-active {
    background: #b7cfe0;
}

.react-tags__suggestions li.is-disabled {
    opacity: 0.5;
    cursor: auto;
}
.ui.form .react-tags__search-input input:not([type]){
    border: 0 !important;
     padding: 0 !important;
}
/*React Tags CSS: end*/

/*AirBnb React-dates CSS: start*/
.ui.form .DateRangePickerInput input[type=text]{
    border: 0 !important;
    border-radius: 0 !important;
}
/*AirBnb React-dates CSS: end*/

img#invoice-paid{
    position: absolute;
    left: 23%;
    opacity: 0.4;
    top: 10%;
    width: 25%;
}

button.pay-invoice-btn{
    width: 100% !important;
    background-color: #774b86 !important;
    text-shadow: none;
    cursor: pointer;
    display: inline-block;
    min-height: 1em;
    outline: 0 !important;
    border: none !important;
    vertical-align: baseline;
    color: rgba(255,255,255,.9);
    font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
    margin: 0 .25em 0 0;
    padding: .78571429em 1.5em .78571429em !important;
    text-transform: none;
    font-weight: 700;
    line-height: 1em;
    font-style: normal;
    text-align: center;
    text-decoration: none;
    border-radius: .28571429rem !important;

}

.Cal__Container__root{
    box-shadow: 0 1px 2px 0 rgba(34,36,38,.15);
    position: -webkit-sticky;
    position: sticky;
    top:10px !important;
}

.deposit-container {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
  margin-top: 40px;
}

.deposit-container-inner {
  width: 270px;
  height: 460px;

  position:absolute; /*it can be fixed too*/
  left:0; right:0;
  top:0; bottom:0;
  margin:auto;

  /*this to solve "the content will not be cut when the window is smaller than the content": */
  max-width:100%;
  max-height:100%;
  /* overflow:auto; */
}

  .stripe-card-container h1 {
    color: #32325d;
    font-weight: 400;
    line-height: 50px;
    font-size: 40px;
    margin: 20px 0;
    padding: 0;
  }

  .stripe-card-container .Checkout {
    margin: 0 auto;
    max-width: 800px;
    box-sizing: border-box;
    padding: 0 5px;
  }

  .stripe-card-container label {
    color: #6b7c93;
    font-weight: 300;
    letter-spacing: 0.025em;
  }

  .stripe-card-container button {
    white-space: nowrap;
    border: 0;
    outline: 0;
    display: inline-block;
    height: 40px;
    line-height: 40px;
    padding: 0 14px;
    box-shadow: 0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08);
    color: #fff;
    border-radius: 4px;
    font-size: 15px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.025em;
    background-color: #6772e5;
    text-decoration: none;
    transition: all 150ms ease;
    margin-top: 10px;
  }

  .stripe-card-container form {
    margin-bottom: 40px;
    padding-bottom: 40px;
    border-bottom: 3px solid #e6ebf1;
  }

  .stripe-card-container button:hover {
    color: #fff;
    cursor: pointer;
    background-color: #7795f8;
    -webkit-transform: translateY(-1px);
            transform: translateY(-1px);
    box-shadow: 0 7px 14px rgba(50, 50, 93, .10), 0 3px 6px rgba(0, 0, 0, .08);
  }

  .stripe-card-container input,
  .stripe-card-container .StripeElement {
    display: block;
    margin: 10px 0 20px 0;
    max-width: 500px;
    padding: 10px 14px;
    font-size: 1em;
    font-family: 'Source Code Pro', monospace;
    box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px, rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
    border: 0;
    outline: 0;
    border-radius: 4px;
    background: white;
  }

  .stripe-card-container input::-webkit-input-placeholder {
    color: #aab7c4;
  }

  .stripe-card-container input::-ms-input-placeholder {
    color: #aab7c4;
  }

  .stripe-card-container input::placeholder {
    color: #aab7c4;
  }

  .stripe-card-container input:focus,
  .stripe-card-container .StripeElement--focus {
    box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px, rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
    transition: all 150ms ease;
  }

  .stripe-card-container .StripeElement.IdealBankElement,
  .stripe-card-container .StripeElement.PaymentRequestButton {
    padding: 0;
  }

.panel-header{
    text-transform:uppercase ;
}

.search-connected-agency .ui.icon.input{
    width:100%;
}

.account-info{
    margin-top: 5px;
}

.ui.vertical.menu{
  width: 100%;
}

.panel-header{
    text-transform:uppercase ;
}

.search-connected-agency .ui.icon.input{
    width:100%;
}

.account-info{
    margin-top: 5px;
}

.profile_name{
    font-size: 24px !important;
    font-weight: bold !important;
    margin-top: 0px;
}
.tab-custom #delete {
    position: absolute;
    top: 19px;
    right: 4px;
    width: 17px;
}
.tab-custom #delete:hover {
    top: 17px;
    font-size: 17px;
}

.tab-custom {
    display: flex;

}
.rating{
    margin-top: 16px;
}

.booking-rating{
    margin-top: 10px;
}

.history-header{
    width:100% !important;
}

.history-date{
    font-size:14px;
    margin-top:-3px !important;
}

.statistics-segment{
    padding-top: 6px !important;
    padding-bottom: 6px !important;
}

.statistic-label{
    font-size: 14px !important;
    margin-top: 3px !important;
    color: rgb(119, 75, 134) !important;
}

.statistic-value{
    font-size: 16px !important;
    margin-bottom: 0px !important;
}

.about-divider{
    margin-top:18px;
    margin-bottom:13px;
}

.booking-divider{
    margin-top:2px;
    margin-bottom:15px;
}

.pdf-resume-img{
    display: inline !important;
    width: 25px;
    height: auto;
    margin-right: 8px;
}

.ui.icon.button>.resume-upload-icon{
    margin-right: 10px !important;
}

.ui.icon.button>.resume-upload-cross{
    margin-left: 10px !important;
}

.full-width-card{
    width: 100% !important;
}

.jobexperience .field{
    margin-bottom: 0px !important;
}

.date-picker{
    width: 100px !important;
    -webkit-appearance: searchfield !important;
    border-radius: .28571429rem !important;
    height: 38px !important;
    border: 1px solid rgba(34,36,38,.15);
    padding-left: 10px !important;
}

.datediv{
    width:165px !important;
    position: relative;
}

.monthpicker{
    position: absolute;
    background-color: #f3f4f5;
    z-index: 999;
    top:30px !important;
    border-radius: 10px !important;
}

.physical_information{
    box-shadow: none !important;
    border: none !important;

}

.image-gallery-image > img{
    height: 600px !important;
    widows: 1000px !important;
}

.image-gallery-thumbnails-container a > div > img{
    height: 50px !important;
    width: 92px !important;
}

.image-gallery-image > span{
    right: -92% !important;
    background:rgba(0,0,0,0) !important;
    top:30px !important;
    bottom:0px !important;
}

.comlumninput {
    width:120px !important;
    max-width: 120px !important;
    min-width: 120px !important;
}

.comlumndelete{
    width:40px !important;
    max-width: 40px !important;
    min-width: 40px !important;
}

.selecttag{
    width:180px !important;
    max-width: 180px !important;
    min-width: 180px !important;
}
.comlumninputeventdate{
    width:100px !important;
    max-width: 100px !important;
    min-width: 100px !important;
}
.jobexperience{
    width: 90% !important;
    max-width: 90% !important;
    min-width: 90% !important;
}

.react-thumbnail-generator > img{
    height: 600px !important;
    widows: 1000px !important;
}

.play-button{
    position: absolute;
    top: 45%;
    left: 45%;
    height: 76px;
    width: 150px;
    border-radius: 10px;
    background-color: rgba(0,0,0,0.5);
    z-index: 99999999;
}
.play-button > i{
    padding-top: 10px !important;
    padding-left: 15px !important;
    cursor: pointer !important;
}
.anchertag{
    color: white !important;
}
.anchertag:hover{
    color: white !important;
    cursor: pointer !important;
}
.image-gallery-description{
    right: -2% !important;
    background:rgba(0,0,0,0) !important;
    top:30px !important;
    bottom:0px !important;
}
.display-selected-video-image > div{
    height: 100px !important;
    width: 100px !important;
}
.display-selected-video-image > img{
    height: 100px !important;
    width: 100px !important;
}
.loading-icon{
    position: absolute !important;
    left: 50% !important;
    z-index: 3 !important;
    top: 44% !important;
}

.ui.blue.progress .bar {
    background-color: #774b86 !important;
}

/*
.jobexperience {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important;

}*/

.container {
  display: flex;
}
h1,
.btn,
#input-field {
  flex: 1 1;
}

.client-photo{
    position: absolute !important;
    top: 5px !important;
    left: 6px !important;
    background-color: #EEE !important;
    border-radius: 6px !important;
    box-shadow: 0px 1px 4px #888 !important;
    width: 90px;
    height: 90px;
    object-fit: cover;
}

.statistic-value{
    font-size: 15px !important;
}

.user-rating {
    margin-bottom: 14px;
}

.statistic-label{
    font-size: 15px !important;
    margin-top: 3px !important;
}

.company-contact-info{
    text-align: left !important;
    margin-left: 10px !important;
    margin-top: 14px !important;
    font-size: 18px !important;
}

.company-photo{
    object-fit: cover;
}

.hr-company-profile{
    margin-top: 14px;
}
.user-name,.user-company-name,.user-rating{
    margin-left: 92px;
}

.company-name{
    margin-bottom: 14px;
}

.company-name,.company-rating{
    margin-left: 11px;
}

.company-user-img{
    width: 28px;
    height: 28px;
    object-fit: cover;
    cursor: pointer;
}

.company-user-name{
    margin-left: 6px;
    font-size: 16px;
    cursor: pointer;
}

.user-list-grid{
    margin-top: -9px !important;
    margin-bottom: 10px !important;
}

.user-list-row{
    padding-bottom: 0 !important;
}
.url-field{
    position: relative !important;
}
.url-field > label{
    position: absolute;
    top:11px !important;
    left: 2px !important;
    font-size: 16px !important;
    z-index: 1 !important;
    color: grey !important;
}
.url-field > div > input{
    padding-left: 52px !important;
}
.card-hover:hover{
    box-shadow: 0 1px 3px 0 #d4d4d5, 0 0 0 1px #d4d4d5;
}

.company-icons{
    color: #774b86;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
}
.company-icons:hover{
    color: #bea5d7;
}

.secondary-fixed-top-menu{
    top:60px !important;
}

.filter-menu-item{
    padding: 1px !important;
}

.filter-menu-item.location{
    min-width: 130px !important;
}

.filter-menu-item.radius,.filter-menu-item.type{
    min-width: 110px !important;
}

.filter-menu-item.clear-button{
    min-width: 158px !important;
}

.filter-menu-item.search-input{
    min-width: 158px !important;
}

.filter-menu-item.search-input input{
    min-width: 157px !important;
}

.filter-menu-item.search-input .field{
    margin-bottom: 0 !important;
}

.filter-menu-item .dropdown{
    border: 0 !important;
    box-shadow: none !important;
}

.filter-menu-item .button{
    padding: 1px;
}

.job-title{
    color: rgba(0,0,0,.87) !important;
    margin-left: 0 !important;
    margin-bottom: 10px !important;
}

.item-desc-client-name{
    padding: 15px 0px !important;
}

.item-desc-client-name i.icon{
    margin-left: -2px !important;
}

a.job-apply-button{
    padding: 3px 30px;
    border: 1px solid;
    float: right;
    text-transform: uppercase;
}

a.job-apply-button.disabled{
    background: mistyrose;
    border: 0;
    color: darkgray;
    cursor: default;
}

/*Mobile */
@media only screen and (max-width: 767px) {
    .filter-menu-item.clear-button{
        height: 44px;
    }
    .filter-menu-item.clear-button button{
        width: 100% !important;
    }

    .search-form{
        width: 100% !important;
    }
}
.secondary-fixed-top-menu{
    top:60px !important;
}

.filter-menu-item{
    padding: 1px !important;
}

.filter-menu-item.location{
    min-width: 147px !important;
}

.filter-menu-item.radius,.filter-menu-item.type{
    min-width: 110px !important;
}

.filter-menu-item.clear-button{
    min-width: 158px !important;
}

.Cal__Day__root.Cal__Day__selected .Cal__Day__selection {
    /*background-color: rgb(138, 84, 156) !important;*/
}

.filter-menu-item.search-input{
    min-width: 158px !important;
}

.filter-menu-item.search-input input{
    min-width: 157px !important;
}

.filter-menu-item.search-input .field{
    margin-bottom: 0 !important;
}

.filter-menu-item .dropdown{
    border: 0 !important;
    box-shadow: none !important;
}

.filter-menu-item .button{
    padding: 1px;
}

.job-title{
    color: rgba(0,0,0,.87) !important;
}

.item-client-name{
    margin-top: 2px !important;
    margin-bottom: 5px !important;
}

.status-button{
    padding: .35em 1.5em .35em !important;
}

.Cal__Container__root{
    box-shadow: 0 1px 2px 0 rgba(34,36,38,.15);
    position: -webkit-sticky;
    position: sticky;
    top:120px;
}

.Cal__Header__date {
    font-size: 18px;
}

.Cal__Header__root{
    min-height: 75px !important;
}

.Cal__Header__dateWrapper.Cal__Header__year{
    height: 15px !important;
}

/*Mobile */
@media only screen and (max-width: 767px) {
    .filter-menu-item.clear-button{
        height: 44px;
    }
    .filter-menu-item.clear-button button{
        width: 100% !important;
    }

    .search-form{
        width: 100% !important;
    }
}

.three-line-text-clamp{
    margin-bottom: 5px;
}

.ui.secondary.menu{
    margin-top: 4px;
}

.shift-time-div{
    max-height: 390px;
    overflow-y: scroll;
    overflow-x:hidden;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    border-bottom: 1px solid #e4e3e3;
    border-top: 1px solid #e4e3e3;
}

.portfolio-div {
    text-align: left;
    margin: 13px;
    margin-left: 0px;
}

.portfolio-list {
    margin-bottom: 20px;
}

.ui.raised.attached.segment{
    padding-bottom: 0;
}

.ui.tiny.modal.transition.visible.active{
    margin-top: 0 !important;
}
.message-row {
  padding-top: 0 !important; }

.users-column {
  padding: 0 !important;
}
  .users-column .left-nav {
    width: 100%;
    height: calc(100vh - 57px);
    background: #FAFAFA;
    /* box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.2);  */
  }
    .users-column .left-nav .top {
      width: 100%;
      height: 72px;
      position: absolute;
      top: 0;
      background: #FAFAFA;
      border-bottom: solid 1px #E0E0E0 !important;
      text-align: center; }
      .users-column .left-nav .top .search {
        margin-top: 18px; }
    .users-column .left-nav .content {
      width: 100%;
      overflow-y: auto;
      position: absolute;
      top: 72px;
      bottom: 72px; }
    .users-column .left-nav .channel-selected{
        background-color: #774b86;
        color: #ffffff;
    }
    .users-column .left-nav .group-item {
      position: relative;
      padding: 15px 15px 15px 22px !important;
      border-bottom: solid 1px #E0E0E0 !important; }
      .users-column .left-nav .group-item .user-image {
        width: 20px !important;
        height: 20px !important;
        object-fit: cover !important;
        border-radius: 50px !important;
        display: inline; }
      .users-column .left-nav .group-item .group-name {
        padding-left: 2%; }

      .users-column .left-nav .group-item .timer {
        float: right;
        font-family: 'Open Sans', sans-serif;
        font-size: 11px;
        padding: 3px 8px;
        color: #BBB;
        background-color: #FFF;
        border: 1px solid #E5E5E5;
        border-radius: 15px;
        margin-top: 12px; }

.messages-column {
    padding: 0 !important; }
  .messages-loader{
    position: absolute !important;
    top: 50% !important;
    left: 50% !important;
  }
  .online {
        position: absolute;
        top: 35px;
        left: 50px;
        width: 15px;
        height: 15px;
        background-color: #8BC34A;
        border-radius: 15px;
        z-index: 1;
  }

  .offline, .nonavailable {
        position: absolute;
        top: 35px;
        left: 50px;
        width: 15px;
        height: 15px;
        background-color: #b7b9c3;
        border-radius: 15px;
        z-index: 1;
  }

  .messages-column .no-chat {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 250px;
    height: 25px; }
  .messages-column .top {
    width: calc(100% - 10px);
    height: 72px;
    position: absolute;
    top: 0;
    background: #FAFAFA;
    border-bottom: solid 1px #E0E0E0 !important;
    margin-left: 3px;
    padding: 26px 1%; }
  .messages-column .middle {
    width: calc(100% - 11px);
    position: absolute;
    top: 73px;
    bottom: 73px;
    overflow: auto;
    padding: 5px 10px; }
  .messages-column .bottom {
    width: calc(100% - 10px);
    height: 72px;
    position: absolute;
    bottom: 0;
    background: #FAFAFA;
    border-bottom: solid 1px #E0E0E0 !important;
    margin-left: 3px;
    padding: 0 1%; }
  .messages-column .user-image {
    width: 50px !important;
    height: 50px !important;
    object-fit: cover !important;
    border-radius: 50px !important;
    display: inline; }
  .messages-column .message {
    margin: 0 0 0 5px;
    background-color: #f6f6f6;
    padding: 15px;
    border-radius: 12px; }
  .messages-column .message-deleted {
    color: red !important;
    font-style: italic !important; }
  .messages-column .time {
    font-size: 10px !important;
    color: lightgrey !important;
    margin-bottom: 10px !important;
    margin-left: 10px !important; }
  .messages-column .settings {
    position: absolute;
    top: 0;
    right: 0; }
  .messages-column .user-typing {
    font-size: 12px;
    font-style: italic;
    color: #757171 !important;
    min-height: 15px;
    padding-top: 3px; }
  .messages-column .send-message-button {
    margin-top: 5px; }

/*# sourceMappingURL=message.css.map */

.gc-message-container > div.App{
  height: calc(100vh - 70px) !important;
  text-align: center;
  margin-right: 20px !important;
}

.gc-message-container > div.App > div{
  height: calc(100vh - 70px) !important;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-intro {
  font-size: large;
}

@-webkit-keyframes App-logo-spin {
  from { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  to { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

@keyframes App-logo-spin {
  from { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  to { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

.rc-trigger-popup.rc-trigger-popup-placement-topLeft{
  position: absolute !important;
  left: auto !important;
  right: 0 !important;
}

.progress-bar-container{
    bottom: 60px;
    width: 100%;
    padding-left: 50px;
    padding-right: 50px;
    position: absolute;
}

.progress-bar{
      background: #774b86;
      min-height: 10px;
      color: white;
  }

.attachment{
    display: block;
    height: 40px;
    background: lightgray;
    margin-top: 10px;
    padding: 10px 0px;
}
.paid-on{
    float: right;
    margin-top: -34px;
    font-size: 14px;
    color: gray;
    text-transform: capitalize;
}

.job-title{

	margin-left:10px;

}
.paid-on{
    float: right;
    margin-top: -34px;
    font-size: 14px;
    color: gray;
}

.job-title{

	margin-left:10px;

}
.content-row .users-column {
  height: calc(100vh - 60px);
  background: #FAFAFA;
  box-shadow: 0 8px 10px rgba(0, 0, 0, 0.2);
  overflow-y: scroll;
}

.content-row .users-column .user-card {
    margin-top: 10px;
}

.content-row .users-column .user-card-preview {
    font-size: 12px !important;
}

.content-row .users-column .user-card-preview img {
    margin-left: 10px !important;
    margin-bottom: 0 !important;
}

.content-row .users-column .user-card-preview .content {
    padding: 5px !important;
}

.content-row .users-column .user-card-preview .description {
    font-size: 12px !important;
}

.content-row .shifts-column .shift-card-container {
  margin-bottom: 10px !important;
}

.content-row .shifts-column .shift-card-container .shift-card.ui.card .header {
    font-size: 1rem !important;
}

.staffs{
  min-height: 20px;
  background-color: rgba(211,211,211,0.5) !important;
  border-radius: 5px;
  padding: 5px !important;
  margin-top: 5px !important;
}

.wordwrap{
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
}

.monthText {
  color:'red'
}

.dayText {
  font-size:25
}

.container-applicant-list{
  margin: 10px 10px 10px !important;
}

.user-card-container{
  display: inline-block;
  opacity: 1;
  margin-bottom: 5px;
}

.user-card-container.user-card-container-dragging{
  opacity: .25;
}

/*# sourceMappingURL=assign-shifts.css.map */

.shift-card-disabled {
    pointer-events: none;
    background-color: grey;
}

.disabled-class{
  background-color: #dadada !important;
}

.active-class .label-class{
  color: green;
}

.three-line-text-clamp{
    margin-bottom: 5px;
}

.ui.secondary.menu{
    margin-top: 4px;
}

.shift-time-div{
    max-height: 390px;
    overflow-y: scroll;
    overflow-x:hidden;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    border-bottom: 1px solid #e4e3e3;
    border-top: 1px solid #e4e3e3;
}

.ui.raised.attached.segment{
    padding-bottom: 0;
}

.ui.tiny.modal.transition.visible.active{
    margin-top: 0 !important;
}
/*@media (min-width: 360px) and (max-width: 991px) {
    .pushable:not(body) > .fixed, .pushable:not(body) > .pusher::after, .pushable:not(body) > .ui.sidebar {
        position: relative !important;
    }
}*/
.loggedinas-toast {
    background-color: aliceblue;
    color: black;
}
.loggedinas-toast a {
    color: rgb(55, 55, 156);
}
