.client-photo{
    position: absolute !important;
    top: 5px !important;
    left: 6px !important;
    background-color: #EEE !important;
    border-radius: 6px !important;
    -webkit-box-shadow: 0px 1px 4px #888 !important;
    box-shadow: 0px 1px 4px #888 !important;
    width: 90px;
    height: 90px;
    object-fit: cover;
}

.statistic-value{
    font-size: 15px !important;
}

.user-rating {
    margin-bottom: 14px;
}

.statistic-label{
    font-size: 15px !important;
    margin-top: 3px !important;
}

.company-contact-info{
    text-align: left !important;
    margin-left: 10px !important;
    margin-top: 14px !important;
    font-size: 18px !important;
}

.company-photo{
    object-fit: cover;
}

.hr-company-profile{
    margin-top: 14px;
}
.user-name,.user-company-name,.user-rating{
    margin-left: 92px;
}

.company-name{
    margin-bottom: 14px;
}

.company-name,.company-rating{
    margin-left: 11px;
}

.company-user-img{
    width: 28px;
    height: 28px;
    object-fit: cover;
    cursor: pointer;
}

.company-user-name{
    margin-left: 6px;
    font-size: 16px;
    cursor: pointer;
}

.user-list-grid{
    margin-top: -9px !important;
    margin-bottom: 10px !important;
}

.user-list-row{
    padding-bottom: 0 !important;
}
.url-field{
    position: relative !important;
}
.url-field > label{
    position: absolute;
    top:11px !important;
    left: 2px !important;
    font-size: 16px !important;
    z-index: 1 !important;
    color: grey !important;
}
.url-field > div > input{
    padding-left: 52px !important;
}
.card-hover:hover{
    box-shadow: 0 1px 3px 0 #d4d4d5, 0 0 0 1px #d4d4d5;
}

.company-icons{
    color: #774b86;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
}
.company-icons:hover{
    color: #bea5d7;
}
