.profile_name{
    font-size: 24px !important;
    font-weight: bold !important;
    margin-top: 0px;
}
.tab-custom #delete {
    position: absolute;
    top: 19px;
    right: 4px;
    width: 17px;
}
.tab-custom #delete:hover {
    top: 17px;
    font-size: 17px;
}

.tab-custom {
    display: flex;

}
.rating{
    margin-top: 16px;
}

.booking-rating{
    margin-top: 10px;
}

.history-header{
    width:100% !important;
}

.history-date{
    font-size:14px;
    margin-top:-3px !important;
}

.statistics-segment{
    padding-top: 6px !important;
    padding-bottom: 6px !important;
}

.statistic-label{
    font-size: 14px !important;
    margin-top: 3px !important;
    color: rgb(119, 75, 134) !important;
}

.statistic-value{
    font-size: 16px !important;
    margin-bottom: 0px !important;
}

.about-divider{
    margin-top:18px;
    margin-bottom:13px;
}

.booking-divider{
    margin-top:2px;
    margin-bottom:15px;
}

.pdf-resume-img{
    display: inline !important;
    width: 25px;
    height: auto;
    margin-right: 8px;
}

.ui.icon.button>.resume-upload-icon{
    margin-right: 10px !important;
}

.ui.icon.button>.resume-upload-cross{
    margin-left: 10px !important;
}

.full-width-card{
    width: 100% !important;
}

.jobexperience .field{
    margin-bottom: 0px !important;
}

.date-picker{
    width: 100px !important;
    -webkit-appearance: searchfield !important;
    border-radius: .28571429rem !important;
    height: 38px !important;
    border: 1px solid rgba(34,36,38,.15);
    padding-left: 10px !important;
}

.datediv{
    width:165px !important;
    position: relative;
}

.monthpicker{
    position: absolute;
    background-color: #f3f4f5;
    z-index: 999;
    top:30px !important;
    border-radius: 10px !important;
}

.physical_information{
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    border: none !important;

}

.image-gallery-image > img{
    height: 600px !important;
    widows: 1000px !important;
}

.image-gallery-thumbnails-container a > div > img{
    height: 50px !important;
    width: 92px !important;
}

.image-gallery-image > span{
    right: -92% !important;
    background:rgba(0,0,0,0) !important;
    top:30px !important;
    bottom:0px !important;
}

.comlumninput {
    width:120px !important;
    max-width: 120px !important;
    min-width: 120px !important;
}

.comlumndelete{
    width:40px !important;
    max-width: 40px !important;
    min-width: 40px !important;
}

.selecttag{
    width:180px !important;
    max-width: 180px !important;
    min-width: 180px !important;
}
.comlumninputeventdate{
    width:100px !important;
    max-width: 100px !important;
    min-width: 100px !important;
}
.jobexperience{
    width: 90% !important;
    max-width: 90% !important;
    min-width: 90% !important;
}

.react-thumbnail-generator > img{
    height: 600px !important;
    widows: 1000px !important;
}

.play-button{
    position: absolute;
    top: 45%;
    left: 45%;
    height: 76px;
    width: 150px;
    border-radius: 10px;
    background-color: rgba(0,0,0,0.5);
    z-index: 99999999;
}
.play-button > i{
    padding-top: 10px !important;
    padding-left: 15px !important;
    cursor: pointer !important;
}
.anchertag{
    color: white !important;
}
.anchertag:hover{
    color: white !important;
    cursor: pointer !important;
}
.image-gallery-description{
    right: -2% !important;
    background:rgba(0,0,0,0) !important;
    top:30px !important;
    bottom:0px !important;
}
.display-selected-video-image > div{
    height: 100px !important;
    width: 100px !important;
}
.display-selected-video-image > img{
    height: 100px !important;
    width: 100px !important;
}
.loading-icon{
    position: absolute !important;
    left: 50% !important;
    z-index: 3 !important;
    top: 44% !important;
}

.ui.blue.progress .bar {
    background-color: #774b86 !important;
}

/*
.jobexperience {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important;

}*/

.container {
  display: flex;
}
h1,
.btn,
#input-field {
  flex: 1;
}
