.secondary-fixed-top-menu{
    top:60px !important;
}

.filter-menu-item{
    padding: 1px !important;
}

.filter-menu-item.location{
    min-width: 147px !important;
}

.filter-menu-item.radius,.filter-menu-item.type{
    min-width: 110px !important;
}

.filter-menu-item.clear-button{
    min-width: 158px !important;
}

.Cal__Day__root.Cal__Day__selected .Cal__Day__selection {
    /*background-color: rgb(138, 84, 156) !important;*/
}

.filter-menu-item.search-input{
    min-width: 158px !important;
}

.filter-menu-item.search-input input{
    min-width: 157px !important;
}

.filter-menu-item.search-input .field{
    margin-bottom: 0 !important;
}

.filter-menu-item .dropdown{
    border: 0 !important;
    box-shadow: none !important;
}

.filter-menu-item .button{
    padding: 1px;
}

.job-title{
    color: rgba(0,0,0,.87) !important;
}

.item-client-name{
    margin-top: 2px !important;
    margin-bottom: 5px !important;
}

.status-button{
    padding: .35em 1.5em .35em !important;
}

.Cal__Container__root{
    box-shadow: 0 1px 2px 0 rgba(34,36,38,.15);
    position: -webkit-sticky;
    position: sticky;
    top:120px;
}

.Cal__Header__date {
    font-size: 18px;
}

.Cal__Header__root{
    min-height: 75px !important;
}

.Cal__Header__dateWrapper.Cal__Header__year{
    height: 15px !important;
}

/*Mobile */
@media only screen and (max-width: 767px) {
    .filter-menu-item.clear-button{
        height: 44px;
    }
    .filter-menu-item.clear-button button{
        width: 100% !important;
    }

    .search-form{
        width: 100% !important;
    }
}
