.secondary-fixed-top-menu{
    top:60px !important;
}

.filter-menu-item{
    padding: 1px !important;
}

.filter-menu-item.location{
    min-width: 130px !important;
}

.filter-menu-item.radius,.filter-menu-item.type{
    min-width: 110px !important;
}

.filter-menu-item.clear-button{
    min-width: 158px !important;
}

.filter-menu-item.search-input{
    min-width: 158px !important;
}

.filter-menu-item.search-input input{
    min-width: 157px !important;
}

.filter-menu-item.search-input .field{
    margin-bottom: 0 !important;
}

.filter-menu-item .dropdown{
    border: 0 !important;
    box-shadow: none !important;
}

.filter-menu-item .button{
    padding: 1px;
}

.job-title{
    color: rgba(0,0,0,.87) !important;
    margin-left: 0 !important;
    margin-bottom: 10px !important;
}

.item-desc-client-name{
    padding: 15px 0px !important;
}

.item-desc-client-name i.icon{
    margin-left: -2px !important;
}

a.job-apply-button{
    padding: 3px 30px;
    border: 1px solid;
    float: right;
    text-transform: uppercase;
}

a.job-apply-button.disabled{
    background: mistyrose;
    border: 0;
    color: darkgray;
    cursor: default;
}

/*Mobile */
@media only screen and (max-width: 767px) {
    .filter-menu-item.clear-button{
        height: 44px;
    }
    .filter-menu-item.clear-button button{
        width: 100% !important;
    }

    .search-form{
        width: 100% !important;
    }
}