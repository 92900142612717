/*@media (min-width: 360px) and (max-width: 991px) {
    .pushable:not(body) > .fixed, .pushable:not(body) > .pusher::after, .pushable:not(body) > .ui.sidebar {
        position: relative !important;
    }
}*/
.loggedinas-toast {
    background-color: aliceblue;
    color: black;
}
.loggedinas-toast a {
    color: rgb(55, 55, 156);
}