.panel-header{
    text-transform:uppercase ;
}

.search-connected-agency .ui.icon.input{
    width:100%;
}

.account-info{
    margin-top: 5px;
}
