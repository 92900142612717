body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.top-container{
    margin-top: 5em;
}
.main-container{
    margin-top:70px;
    width: 100% !important;
}

.full-width-container{
    width: 99% !important;
}

.field-error{
    margin-top: -24px;
    display: block;
    color:red;
    min-height: 25px;
    text-align: left;
}

.radio-field-error{
    position: absolute;
    margin-top: -35px;
    color:red;
}

/* bad way to add spacing below a field
.ui.form .field{
    margin: 0 0 22px !important;
} */

.ui.inverted.menu {
    border: 0 solid transparent;
    background: #774b86;
}

.custom-anchor-tag{
    -webkit-box-shadow:none !important;
    box-shadow: none !important;
    border: none !important;
    text-decoration: none !important;
    color: #774b86 !important;
    font-weight: bold !important;
    font-size: 16px !important;
    padding: 0 !important;
}

.custom-anchor-tag:hover{
    text-decoration: underline !important;
}

hr{
    border: 0;
    border-top: 1px solid #dededf;
    padding: 0;
}

.text-max-lenght-message{
    position: absolute;
    top:5px;
    color:#cac4c4;
    right: 12px;
    font-size: 12px;
    font-weight: bold;
}

.required-marker{
    color: red;
    margin-right: 4px !important;
}

/*Semantic UI Modal position error fixes*/
.ui.page.modals.transition.visible {
    display: flex !important;
}
.jumbotron.sub-page {
    background: #f6eef9 !important;
}

 /*Mobile */
@media only screen and (max-width: 784px) {
    .main-container{
        margin-top:80px;
    }
}

 /*Tablet / iPad Portrait */
@media only screen and (min-width: 785px) and (max-width: 991px) {
    .main-container{
        margin-top:80px;
    }
}

 /*Computer / Desktop / iPad Landscape */
@media only screen and (min-width: 992px) and (max-width: 1199px) {

}

 /*Large Monitor */
@media only screen and (min-width: 1200px) and (max-width: 1919px) {
    .ui.container {
        width: 1127px;
    }
}

 /*Widescreen Monitor */
@media only screen and (min-width: 1920px) {

}

.Cal__Header__root{
    min-height: 60px !important;
    padding: 10px !important;
}

.Cal__Weekdays__day{
    padding: 5px 0px !important;;
}

.Cal__Header__dateWrapper.Cal__Header__day{
    font-size: 14px !important;
}

.Cal__Day__root{
    font-size: 12px !important;
}
.tp-text{
    color:#949294 !important;
    /* text-shadow: 2px 2px 23px #e9e5ea, 1px 1px 6px #bea4d5; */
}

.footer-container{
    background-color:#f8f9fa;
    padding-top: 50px;
    padding-bottom: 50px;
    /* margin-top: 30px !important; */
    box-shadow: 0 50vh 0 50vh #d4d4d5;
}

.react-tel-input{
    margin-bottom: 20px !important;
    height: 30px !important;
}

.react-tel-input input{
    padding-left: 45px !important;
    height: 30px !important;
}

.ui.blue.button, .ui.blue.buttons .button {
    background-color: #774b86 !important;
    color: #fff;
    text-shadow: none;
    background-image: none;
}

.ui.blue.button:hover, .ui.blue.buttons .button:hover {
    background-color: #9D7EB0 !important;
    color: #fff;
    text-shadow: none;
}

a:hover, a:focus, a:active {
    color: #21ba45 ;
    text-decoration: none;
}
.ui.segments {
    border: 1px solid #e3dee8 !important;
}
.ui.secondary.segment {
    background: #f6f5f7 !important;
    color: rgba(0,0,0,.6);
}

.ui.attached.info.message, .ui.info.message {
    -webkit-box-shadow: 0 0 0 1px #a9d5de inset, 0 0 0 0 transparent;
    box-shadow: 0 0 0 1px #774a85 inset, 0 0 0 0 transparent !important;
}

.ui.info.message {
    background-color: #f6f5f7 !important;
    color: #774a85;
}

.Cal__Header__root{
    background-color: rgb(119, 74, 133)!important;
    color: rgb(255, 255, 255);
}
.Cal__Weekdays__root{
    background-color: #8a549c !important;
    color: rgb(255, 255, 255);
}

.Cal__Day__root.Cal__Day__selected .Cal__Day__selection {
/*    background-color: rgb(138, 84, 156) !important;*/
}

.Cal__Today__root.Cal__Today__show {
    background-color: #bababc !important;
}

i.blue.icon {
    color: #8a549c !important;
}

.Cal__Today__chevron {
    color: #8a549c !important;
}
.ui.circular.label {
    margin-left: 4px !important;
}
