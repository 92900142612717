.centered-image{
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.intro{
    font-size: 18px;
    color:#484848;
    margin-top: 30px;
    margin-bottom: 40px;
}

.upload-button{
	width: 100%;
}

.signup-container-inner {
  position: absolute;
  top: 37%;
  left: 50%;
  transform: translate(-50%, -35%);
}

.top-to-bottom {
  border-width: 3px;
  border-style: solid;
  -webkit-border-image:
    -webkit-gradient(linear, 0 0, 0 100%, from(black), to(rgba(0, 0, 0, 0))) 1 100%;
  -webkit-border-image:
    -webkit-linear-gradient(black, rgba(0, 0, 0, 0)) 1 100%;
  -moz-border-image:
    -moz-linear-gradient(black, rgba(0, 0, 0, 0)) 1 100%;
  -o-border-image:
    -o-linear-gradient(black, rgba(0, 0, 0, 0)) 1 100%;
  border-image:
    linear-gradient(to bottom, black, rgba(0, 0, 0, 0)) 1 100%;
}

@media only screen and (max-width: 767px) {
  .ui.form .fields {
    flex-wrap: inherit !important;
  }
}

.recaptcha-custom {
  display: -webkit-inline-box;
  margin-top: 20px;
  min-height: 40px;
}
