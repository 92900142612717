.three-line-text-clamp{
    margin-bottom: 5px;
}

.ui.secondary.menu{
    margin-top: 4px;
}

.shift-time-div{
    max-height: 390px;
    overflow-y: scroll;
    overflow-x:hidden;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    border-bottom: 1px solid #e4e3e3;
    border-top: 1px solid #e4e3e3;
}

.ui.raised.attached.segment{
    padding-bottom: 0;
}

.ui.tiny.modal.transition.visible.active{
    margin-top: 0 !important;
}