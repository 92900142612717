.box {
  box-shadow: 0 0 0 0 rgba(34,36,38,.15) !important;
}
.day-text {
  color: #666 !important;
  margin: 0px !important;
}

.date-text {
  color: #000 !important;
  margin: 0px !important;
  line-height: 1.28571429em !important;
}

.month-text {
  color: #f00 !important;
  margin: 0px !important;
  /* line-height: 1.28571429em !important; */
}
