.gc-message-container > div.App{
  height: calc(100vh - 70px) !important;
  text-align: center;
  margin-right: 20px !important;
}

.gc-message-container > div.App > div{
  height: calc(100vh - 70px) !important;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

.rc-trigger-popup.rc-trigger-popup-placement-topLeft{
  position: absolute !important;
  left: auto !important;
  right: 0 !important;
}

.progress-bar-container{
    bottom: 60px;
    width: 100%;
    padding-left: 50px;
    padding-right: 50px;
    position: absolute;
}

.progress-bar{
      background: #774b86;
      min-height: 10px;
      color: white;
  }

.attachment{
    display: block;
    height: 40px;
    background: lightgray;
    margin-top: 10px;
    padding: 10px 0px;
}