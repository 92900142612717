@media only screen and (min-width: 576px) {
    .event-signup-container {
        width: 577px !important;
    }

    ui.modal{
        width: 60% !important;
    }
}

.modal{
    margin-top: 0px !important;
}

.modal > .content{
    /* width: 70% !important; */
    float: none !important;
    margin: 0 auto !important;
}

.modal .content .field{
    display: inline;
}

.modal .content .field .input{
    width: 100%;
}

.rbc-toolbar .rbc-btn-group{
    display: none;
}

.react-tagsinput{
    margin-bottom: 10px;
}

.field-error{
    margin-top: 0 !important;
}

.role-checkbox{
    padding: 5px !important;
    height: 45px !important;
}

.role-radio{
    padding: 10px !important;
}

.role-radio label{
    color: white !important;
}

.step1-segment .ui.form .field{
    margin-bottom:0 !important;
}

.Cal__Day__root.Cal__Day__selected .Cal__Day__selection {
    /*background-color: rgb(138, 84, 156) !important;*/
}

.step1-primary-info-row{
    padding-bottom: 0 !important;
}

.grid-event-locations{
     margin: 0 !important;
}

.role-cell{
  padding-left:2px !important;
  padding-right:2px !important;
}

/*Google location css: start*/
.google-loc-search-input-container {
    position: relative;
}

.google-loc-search-input-container input {
    padding: 10px !important;
}

.google-loc-search-input,
.google-loc-search-input:focus,
.google-loc-search-input:active {
    box-shadow: 0 2px 2px 0 rgba(0,0,0,0.16), 0 0 0 1px rgba(0,0,0,0.08);
    border: honeydew;
    display: block;
    width: 100%;
    padding: 16px;
    font-size: 16px;
    border-radius: 2px;
    outline: none;
}

.google-loc-clear-button,
.google-loc-clear-button:active,
.google-loc-clear-button:focus {
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
    background: transparent;
    border: none;
    outline: none;
    font-weight: 600;
    color: #999;
}

.google-loc-autocomplete-container {
    border-bottom: honeydew;
    border-left: honeydew;
    border-right: honeydew;
    border-top: 1px solid #e6e6e6;
    box-shadow: 0 2px 4px rgba(0,0,0,0.2);
    border-radius: 0 0 2px 2px;
}

.google-loc-suggestion-item {
    padding: 8px;
    text-align: left;
    background-color: #fff;
    cursor: pointer;
}

.google-loc-suggestion-item--active {
    background-color: #fafafa;
}
/*Google location css: end*/

/*React Tags CSS: start*/
.react-tags {
    position: relative;
    padding: 6px 0 0 6px;
    border: 1px solid #D1D1D1;
    border-radius: 1px;

    /* shared font styles */
    font-size: 1em;
    line-height: 1.2;

    /* clicking anywhere will focus the input */
    cursor: text;
    margin-top: 10px !important;
}

.react-tags.is-focused {
    border-color: #B1B1B1;
}

.react-tags__selected {
    display: inline;
}

.react-tags__selected-tag {
    display: inline-block;
    box-sizing: border-box;
    margin: 0 6px 6px 0;
    padding: 6px 8px;
    border: 1px solid #D1D1D1;
    border-radius: 2px;
    background: #F1F1F1;

    /* match the font styles */
    font-size: inherit;
    line-height: inherit;
}

.react-tags__selected-tag:after {
    content: '\2715';
    color: #AAA;
    margin-left: 8px;
}

.react-tags__selected-tag:hover,
.react-tags__selected-tag:focus {
    border-color: #B1B1B1;
}

.react-tags__search {
    display: inline-block;

    /* match tag layout */
    padding: 7px 2px;
    margin-bottom: 6px;

    /* prevent autoresize overflowing the container */
    max-width: 100%;
}

@media screen and (min-width: 30em) {

    .react-tags__search {
        /* this will become the offsetParent for suggestions */
        position: relative;
    }

}

.react-tags__search input {
    /* prevent autoresize overflowing the container */
    max-width: 100%;

    /* remove styles and layout from this element */
    margin: 0;
    padding: 0;
    border: 0;
    outline: none;

    /* match the font styles */
    font-size: inherit;
    line-height: inherit;
}

.react-tags__search input::-ms-clear {
    display: none;
}

.react-tags__suggestions {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    z-index: 1000000;
}

@media screen and (min-width: 30em) {

    .react-tags__suggestions {
        width: 240px;
    }

}

.react-tags__suggestions ul {
    margin: 4px -1px;
    padding: 0;
    list-style: none;
    background: white;
    border: 1px solid #D1D1D1;
    border-radius: 2px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
}

.react-tags__suggestions li {
    border-bottom: 1px solid #ddd;
    padding: 6px 8px;
}

.react-tags__suggestions li mark {
    text-decoration: underline;
    background: none;
    font-weight: 600;
}

.react-tags__suggestions li:hover {
    cursor: pointer;
    background: #eee;
}

.react-tags__suggestions li.is-active {
    background: #b7cfe0;
}

.react-tags__suggestions li.is-disabled {
    opacity: 0.5;
    cursor: auto;
}
.ui.form .react-tags__search-input input:not([type]){
    border: 0 !important;
     padding: 0 !important;
}
/*React Tags CSS: end*/

/*AirBnb React-dates CSS: start*/
.ui.form .DateRangePickerInput input[type=text]{
    border: 0 !important;
    border-radius: 0 !important;
}
/*AirBnb React-dates CSS: end*/

img#invoice-paid{
    position: absolute;
    left: 23%;
    opacity: 0.4;
    top: 10%;
    width: 25%;
}

button.pay-invoice-btn{
    width: 100% !important;
    background-color: #774b86 !important;
    text-shadow: none;
    cursor: pointer;
    display: inline-block;
    min-height: 1em;
    outline: 0 !important;
    border: none !important;
    vertical-align: baseline;
    color: rgba(255,255,255,.9);
    font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
    margin: 0 .25em 0 0;
    padding: .78571429em 1.5em .78571429em !important;
    text-transform: none;
    font-weight: 700;
    line-height: 1em;
    font-style: normal;
    text-align: center;
    text-decoration: none;
    border-radius: .28571429rem !important;

}

.Cal__Container__root{
    box-shadow: 0 1px 2px 0 rgba(34,36,38,.15);
    position: -webkit-sticky;
    position: sticky;
    top:10px !important;
}

.deposit-container {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
  margin-top: 40px;
}

.deposit-container-inner {
  width: 270px;
  height: 460px;

  position:absolute; /*it can be fixed too*/
  left:0; right:0;
  top:0; bottom:0;
  margin:auto;

  /*this to solve "the content will not be cut when the window is smaller than the content": */
  max-width:100%;
  max-height:100%;
  /* overflow:auto; */
}
