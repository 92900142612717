.location-search-input{
    width: 300px;
    height: 40px;
    padding: 2px 5px;
    text-align: center;
}

.suggestion-item, .suggestion-item-active{
    min-height: 30px;
    line-height: 30px;
}

.autocomplete-dropdown-container{
    width: 300px;
    margin: 0 auto;
    float: none;
}

div[id='^PlacesAutocomplete__suggestion']{
    min-height: 30px;
    line-height: 30px;
}